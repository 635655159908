import React, { useState } from 'react';
import './Subscribe.css';

const Subscribe = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = (event) => {
    event.preventDefault();
    // Add your subscription handling logic here
    console.log('Subscribed with email:', email);
    // Clear the input field after subscription
    setEmail('');
  };

  return (
    <div className='SC'>
      <div  className='SubscribeWhole'>
        <div className='SubscribeTextBox'>
        <div className='SubscribeTopic'>Subscribe Now</div>
        <div className='SubscribeDescription'>We are always adding new courses and blogs to our website. Sign up for our newsletter to be the first to know about our latest offerings.</div>
      </div>
      
      <div className='EmailSide'>
        <form className='EmailForm' onSubmit={handleSubscribe}>
          <input className='EmailInput'
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            
          />
          <button className='SubscribeButton' type="submit">Subscribe</button>
        </form>
      </div>
      </div>
    </div>
  );
}

export default Subscribe;
