import React, { useRef } from 'react';
import ListCourses from './ListCourses';
import './Biomed.css';
import grid from '../assets/grid.png';
import binary from '../assets/binary.png';
import biomed from '../assets/biomed.png';
import bio1 from '../assets/bio1.png';
import bio2 from '../assets/bio2.png';
import bio3 from '../assets/bio3.png';
import bio4 from '../assets/bio4.png';

const Biomed = () => {
  const listCoursesRef = useRef(null);

  const handleViewCoursesClick = () => {
    if (listCoursesRef.current) {
      listCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="biomed">
      <div className="content-biomed">
        <div className="text-container-biomed">
          <div className="title-biomed">Bio Medical</div>
          <div className="description-biomed">
          Unlock the secrets of life with our Genetic Engineering courses, providing you with the knowledge to innovate in biotechnology and medical research.
          </div>
          <button className="round-button-biomed" onClick={handleViewCoursesClick}>View Courses</button>
        </div>
        <div className="image-container-biomed">
          <div className="stack-imgs-biomed">
            <img src={grid} alt="grid" />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={biomed} alt="biomedical" />
          </div>
          <div className="scatter-around-biomed">
            <img src={bio1} alt="bio1" />
            <img src={bio2} alt="bio2" />
            <img src={bio3} alt="bio3" />
            <img src={bio4} alt="bio4" />
          </div>
        </div>
      </div>
      <div ref={listCoursesRef}>
        <ListCourses expanded={false} />
      </div>
    </div>
  );
};

export default Biomed;
