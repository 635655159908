// components/Navigation.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';
import logoImage from '../assets/Asset 4.png';
import burgerIcon from '../assets/try.png';

const Navigation = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const sidebarRef = useRef(null);

  const toggleNavigation = () => {
    setIsActive(!isActive);
  };

  const toggleDropdown = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(index);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !event.target.closest('.burger-icon')) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const courses = [
    {
      title: 'Computer Science & IT',
      subcourses: [
        { name: 'Artificial Intelligence', path: 'ai' },
        { name: 'Data Science', path: 'datascience' },
        { name: 'Machine Learning', path: 'ml' },
        { name: 'Cyber Security', path: 'cyber' },
        { name: 'Python', path: 'python' },
        { name: 'Cloud Computing', path: 'cloud' },
        { name: 'Full Stack Development', path: 'fullstack' },
        { name: 'Java', path: 'java' },
        { name: 'Android App Development', path: 'androidapp' },
      ],
    },
    {
      title: 'Civil',
      subcourses: [
        { name: 'Construction Planning and Management', path: 'constructionplanning' },
        { name: 'SketchUp', path: 'sketchup' },
        { name: 'AutoCad & Revit', path: 'autocad' },
      ],
    },
    {
      title: 'ECE & EEE',
      subcourses: [
        { name: 'Embedded Systems', path: 'embedded' },
        { name: 'Internet of Things (IoT)', path: 'iot' },
      ],
    },
    {
      title: 'Mechanical',
      subcourses: [
        { name: 'Car Designing', path: 'cardesign' },
        { name: 'AUTO CAD', path: 'autocadm' },
      ],
    },
    {
      title: 'Bio Medical',
      subcourses: [
        { name: 'Genetic Engineering', path: 'geniticengineering' },
        { name: 'Nano Science and Nano Technology', path: 'nanoscience' },
      ],
    },
    {
      title: 'Management',
      subcourses: [
        { name: 'Finance', path: 'finance' },
        { name: 'Sales & Marketing', path: 'marketing' },
        { name: 'HR', path: 'hr' },
        { name: 'Business Analytics', path: 'b_analytics' },
        { name: 'Digital Marketing', path: 'digimar' },
      ],
    },
    {
      title: 'Skill Development',
      subcourses: [
        { name: 'English Speaking', path: 'englishspeaking' },
        { name: 'Tableau and Power BI', path: 'tableau' },
        { name: 'UI/UX Design', path: 'uiux' },
        { name: 'Graphic Design', path: 'graphic' },
      ],
    },
  ];

  return (
    <nav className={`navigation ${isActive ? 'active' : ''}`}>
      <div className="ruddo-logo">
        <Link to="/"><img src={logoImage} alt="Logo" /></Link>
      </div>
      
      <div className={`nav-links ${isActive ? 'active' : ''}`}>
        <div className="dropdown">
          <button className="dropbtn NormalCharacterStyle3">Programs</button>
          <div className="dropdown-content">
            {courses.map((course, index) => (
              <div key={index} className="dropdown">
                <button
                  className="dropbtn NormalCharacterStyle3"
                  onClick={() => toggleDropdown(index)}
                >
                  {course.title}
                </button>
                <div className={`dropdown-subcontent ${activeDropdown === index ? 'show' : ''}`}>
                  {course.subcourses.map((subcourse, subIndex) => (
                    <Link key={subIndex} to={`/domain/${subcourse.path}`} className="subcourse-link">
                      {subcourse.name}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
            <Link to="/internship" className="subcourse-link explore-link">
              Explore
            </Link>
          </div>
        </div>
        <Link to="/about" className="NormalCharacterStyle3">About</Link>
        <Link to="/blog" className="NormalCharacterStyle3">Blog</Link>
        <Link to="/contact" className="NormalCharacterStyle3">Contact</Link>
        <Link to="/login" className="NormalCharacterStyle17" style={{ fontWeight: 'bold' }}>LOGIN</Link>
      </div>

      <div className="burger-icon" onClick={toggleNavigation}>
        <img src={burgerIcon} alt="Menu" />
      </div>

      <div className={`sidebar ${isActive ? 'show' : ''}`} ref={sidebarRef}>
        <div className="dropdown">
          <button className="dropbtn NormalCharacterStyle3">Programs</button>
          <div className="dropdown-content">
            {courses.map((course, index) => (
              <div key={index} className="dropdown">
                <button
                  className="dropbtn NormalCharacterStyle3"
                  onClick={() => toggleDropdown(index)}
                >
                  {course.title}
                </button>
                <div className={`dropdown-subcontent ${activeDropdown === index ? 'show' : ''}`}>
                  {course.subcourses.map((subcourse, subIndex) => (
                    <Link key={subIndex} to={`/domain/${subcourse.path}`} className="subcourse-link">
                      {subcourse.name}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
            <Link to="/internship" className="subcourse-link-explore-link">
              Explore
            </Link>
          </div>
        </div>
        <Link to="/about" className="NormalCharacterStyle3">About</Link>
        <Link to="/blog" className="NormalCharacterStyle3">Blog</Link>
        <Link to="/contact" className="NormalCharacterStyle3">Contact</Link>
        <Link to="/login" className="NormalCharacterStyle17" style={{ fontWeight: 'bold' }}>LOGIN</Link>
      </div>
    </nav>
  );
};

export default Navigation;