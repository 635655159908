// RuddoAdv.js
import React from 'react';
import './RuddoAdv.css';
import ad4 from '../assets/ad1.png'
import ad1 from '../assets/ad2.png'
import ad2 from '../assets/ad3.png'
import ad3 from '../assets/ad4.png'
import ad6 from '../assets/ad5.png'
import ad5 from '../assets/ad6.png'

const RuddoAdv = () => {
  return (
    <div className="ruddo-adv-outer">
    <section className="ruddo-adv">
      <h2>Experience The Ruddo Advantage</h2>
      <p className="subtext">Whether you're looking to upskill, switch careers, or explore new interests, our diverse course options provide the guidance and support you need to chart your own course to success.</p>
      <div className="ruddo-adv-list">
        <div className="advantage-card">
          <div className="logo111">
          <img src={ad1} alt="Advantage" />
          </div>
          <p className="advantage">Industry-Focused, Non-Traditional Approach</p>
        </div>
        <div className="advantage-card">
          <div className="logo11">
            <img src={ad2} alt="Advantage" />
          </div>
          <p className="advantage">Curated Curriculum</p>
        </div>
        <div className="advantage-card">
          <div className="logo11">
          <img src={ad3} alt="Advantage" />
          </div>
          <p className="advantage">Certified Industrial Experts</p>
        </div>
        <div className="advantage-card">
          <div className="logo11">
          <img src={ad4} alt="Advantage" />
          </div>
          <p className="advantage">Get Expert Support</p>
        </div>
        <div className="advantage-card">
          <div className="logo11">
          <img src={ad5} alt="Advantage" />
          </div>
          <p className="advantage">Placement Assistance</p>
        </div>
        <div className="advantage-card">
          <div className="logo111">
          <img src={ad6} alt="Advantage" />
          </div>
          <p className="advantage">Resume Enhancement and Certification</p>
        </div>
      </div>
    </section>
    </div>

  );
}

export default RuddoAdv;
