import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './ListCourses.css';
import { Link } from 'react-router-dom';

const ListCourses = forwardRef((props, ref) => {
  const [expandedCourse, setExpandedCourse] = useState(null); // Local state for expanded course
  const listRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToComponent: () => {
      if (listRef.current) {
        listRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }));

  const handleCourseClick = (event, course) => {
    event.preventDefault(); // Prevent the default behavior of anchor tag
    if (expandedCourse === course) {
      setExpandedCourse(null); // Collapse the expanded state
    } else {
      setExpandedCourse(course); // Expand the state for the selected course
    }
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className='list-courses-body'>
      <div className={`list-courses ${expandedCourse ? 'expanded' : ''}`} ref={listRef}>
        <div className="title">All Courses</div>
        <div className="course-list">
          <a
            href="#"
            onClick={(e) => handleCourseClick(e, 'Computer Science & IT')}
            className={expandedCourse === 'Computer Science & IT' ? 'selected' : ''}
          >
            Computer Science & IT <span className="arrow">▼</span>
          </a>
          {expandedCourse === 'Computer Science & IT' && (
            <div className="SubCourses">
              <Link to="/domain/ai" onClick={handleLinkClick}>Artificial Intelligence</Link>
              <Link to="/domain/datascience" onClick={handleLinkClick}>Data Science</Link>
              <Link to="/domain/ml" onClick={handleLinkClick}>Machine Learning</Link>
              <Link to="/domain/cyber" onClick={handleLinkClick}>Cyber Security</Link>
              <Link to="/domain/python" onClick={handleLinkClick}>Python</Link>
              <Link to="/domain/cloud" onClick={handleLinkClick}>Cloud Computing</Link>
              <Link to="/domain/fullstack" onClick={handleLinkClick}>Full Stack Development</Link>
              <Link to="/domain/java" onClick={handleLinkClick}>Java</Link>
              <Link to="/domain/androidapp" onClick={handleLinkClick}>Android App Development</Link>
            </div>
          )}

          <hr />
          <a
            href="#"
            onClick={(e) => handleCourseClick(e, 'Civil')}
            className={expandedCourse === 'Civil' ? 'selected' : ''}
          >
            Civil <span className="arrow">▼</span>
          </a>
          {expandedCourse === 'Civil' && (
            <div className="SubCourses">
              <Link to="/domain/constructionplanning" onClick={handleLinkClick}>Construction Planning and Management</Link>
              <Link to="/domain/sketchup" onClick={handleLinkClick}>SketchUp</Link>
              <Link to="/domain/autocad" onClick={handleLinkClick}>AutoCad & Revit</Link>
            </div>
          )}

          <hr />
          <a
            href="#"
            onClick={(e) => handleCourseClick(e, 'ECE & EEE')}
            className={expandedCourse === 'ECE & EEE' ? 'selected' : ''}
          >
            ECE & EEE <span className="arrow">▼</span>
          </a>
          {expandedCourse === 'ECE & EEE' && (
            <div className="SubCourses">
              <Link to="/domain/embedded" onClick={handleLinkClick}>Embedded Systems</Link>
              <Link to="/domain/iot" onClick={handleLinkClick}>Internet of Things (IoT)</Link>
            </div>
          )}

          <hr />
          <a
            href="#"
            onClick={(e) => handleCourseClick(e, 'Mechanical')}
            className={expandedCourse === 'Mechanical' ? 'selected' : ''}
          >
            Mechanical <span className="arrow">▼</span>
          </a>
          {expandedCourse === 'Mechanical' && (
            <div className="SubCourses">
              <Link to="/domain/cardesign" onClick={handleLinkClick}>Car Designing</Link>
              <Link to="/domain/autocadm" onClick={handleLinkClick}>AUTO CAD</Link>
            </div>
          )}

          <hr />
          <a
            href="#"
            onClick={(e) => handleCourseClick(e, 'Bio Medical')}
            className={expandedCourse === 'Bio Medical' ? 'selected' : ''}
          >
            Bio Medical <span className="arrow">▼</span>
          </a>
          {expandedCourse === 'Bio Medical' && (
            <div className="SubCourses">
              <Link to="/domain/geniticengineering" onClick={handleLinkClick}>Genetic Engineering</Link>
              <Link to="/domain/nanoscience" onClick={handleLinkClick}>Nano Science and Nano Technology</Link>
            </div>
          )}

          <hr />
          <a
            href="#"
            onClick={(e) => handleCourseClick(e, 'Management')}
            className={expandedCourse === 'Management' ? 'selected' : ''}
          >
            Management <span className="arrow">▼</span>
          </a>
          {expandedCourse === 'Management' && (
            <div className="SubCourses">
              <Link to="/domain/finance" onClick={handleLinkClick}>Finance</Link>
              <Link to="/domain/marketing" onClick={handleLinkClick}>Sales & Marketing</Link>
              <Link to="/domain/hr" onClick={handleLinkClick}>HR</Link>
              <Link to="/domain/b_analytics" onClick={handleLinkClick}>Business Analytics</Link>
              <Link to="/domain/digimar" onClick={handleLinkClick}>Digital Marketing</Link>
            </div>
          )}

          <hr />
          <a
            href="#"
            onClick={(e) => handleCourseClick(e, 'Skill Development')}
            className={expandedCourse === 'Skill Development' ? 'selected' : ''}
          >
            Skill Development <span className="arrow">▼</span>
          </a>
          {expandedCourse === 'Skill Development' && (
            <div className="SubCourses">
              <Link to="/domain/englishspeaking" onClick={handleLinkClick}>English Speaking</Link>
              <Link to="/domain/tableau" onClick={handleLinkClick}>Tableau and Power BI</Link>
              <Link to="/domain/uiux" onClick={handleLinkClick}>UI/UX Design</Link>
              <Link to="/domain/graphic" onClick={handleLinkClick}>Graphic Design</Link>
            </div>
          )}

          <hr />
        </div>
      </div>
    </div>
  );
});

export default ListCourses;
