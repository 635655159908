// CurriculumContent.js

const curriculumContent = {
  ai: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Predictive Analysis',
        details: [
          'Introduction to predictive analysis and its applications.',
          'Regression analysis: Linear regression and evaluation metrics.',
          'Classification analysis: Logistic regression and evaluation metrics.'
        ]
      },
      {
        number: 2,
        name: 'Machine Learning Algorithms',
        details: [
          'Supervised learning algorithms: Decision trees, Random forests, Support Vector Machines (SVM).',
          'Unsupervised learning algorithms: K-means clustering, Hierarchical clustering.',
          'Ensemble learning techniques.'
        ]
      },
      {
        number: 3,
        name: 'Deep Learning Fundamentals',
        details: [
          'Introduction to neural networks.',
          'Deep learning frameworks: TensorFlow and Keras.',
          'Building and training neural networks for image classification and natural language processing tasks.'
        ]
      },
      {
        number: 4,
        name: 'Advanced Topics in AI',
        details: [
          'Reinforcement learning fundamentals.',
          'Generative adversarial networks (GANs).',
          'Transfer learning and fine-tuning pretrained models.'
        ]
      },
      {
        number: 5,
        name: 'AI Ethics and Responsible AI',
        details: [
          'Ethical considerations in AI.',
          'Bias and fairness in AI algorithms.',
          'Regulatory frameworks and guidelines for AI development and deployment.'
        ]
      }
    ]
  },
  ml: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Predictive Analysis',
        details: [
          'Introduction to predictive analysis and its applications.',
          'Regression analysis: Linear regression and evaluation metrics.',
          'Classification analysis: Logistic regression and evaluation metrics.'
        ]
      },
      {
        number: 2,
        name: 'Machine Learning Algorithms',
        details: [
          'Supervised learning algorithms: Decision trees, Random forests, Support Vector Machines (SVM).',
          'Unsupervised learning algorithms: K-means clustering, Hierarchical clustering.',
          'Ensemble learning techniques.'
        ]
      },
      {
        number: 3,
        name: 'Deep Learning Fundamentals',
        details: [
          'Introduction to neural networks.',
          'Deep learning frameworks: TensorFlow and Keras.',
          'Building and training neural networks for image classification and natural language processing tasks.'
        ]
      },
      {
        number: 4,
        name: 'Advanced Topics in AI',
        details: [
          'Reinforcement learning fundamentals.',
          'Generative adversarial networks (GANs).',
          'Transfer learning and fine-tuning pretrained models.'
        ]
      },
      {
        number: 5,
        name: 'AI Ethics and Responsible AI',
        details: [
          'Ethical considerations in AI.',
          'Bias and fairness in AI algorithms.',
          'Regulatory frameworks and guidelines for AI development and deployment.'
        ]
      }
    ]
  },

  cyber: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Cybersecurity',
        details: [
          'Overview of Cybersecurity: Introduction to the field, its importance, and the basic concepts.',
          'Common Cyber Threats: Explanation of common threats like malware, phishing, and social engineering.',
          'Security Principles: Introduction to key principles like confidentiality, integrity, and availability.'
        ]
      },
      {
        number: 2,
        name: 'Network Security',
        details: [
          'Network Basics: Overview of network protocols, TCP/IP, and the OSI model.',
          'Network Vulnerabilities: Understanding common vulnerabilities and best practices to secure networks.',
          'Introduction to Firewalls: Learn about firewalls and their role in network security.'
        ]
      },
      {
        number: 3,
        name: 'Secure Systems and Applications',
        details: [
          'Operating System Security: Basic concepts of securing operating systems like Windows, Linux, and macOS.',
          'Secure Coding Practices: Introduction to secure coding principles and common vulnerabilities to avoid.',
          'Web Application Security: Overview of web application vulnerabilities and mitigation techniques.'
        ]
      },
      {
        number: 4,
        name: 'Cyber Hygiene and Best Practices',
        details: [
          'Password Security: Best practices for creating and managing strong passwords.',
          'Software Updates and Patching: Importance of keeping software and systems up to date.',
          'User Awareness and Social Engineering: Understanding the role of user awareness in preventing attacks.'
        ]
      },
      {
        number: 5,
        name: 'Capstone Project and Wrap-up',
        details: [
          'Capstone Project: Engage in a small-scale hands-on project to apply the knowledge gained during the course.',
          'Project Presentation: Present the project findings and share experiences with the class.',
          'Course Review and Next Steps: Review key concepts, address questions, and provide guidance for further learning and development.'
        ]
      }
    ]
  },
  cloud: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Cloud Computing',
        details: [
          'Understanding the basics of cloud computing.',
          'Different cloud service models: IaaS, PaaS, SaaS.',
          'Overview of popular cloud platforms: AWS, Azure, GCP.'
        ]
      },
      {
        number: 2,
        name: 'Cloud Infrastructure and Virtualization',
        details: [
          'Introduction to virtualization technologies.',
          'Creating and managing virtual machines in the cloud.',
          'Overview of containerization using Docker.',
          'Managing containerized applications in the cloud.'
        ]
      },
      {
        number: 3,
        name: 'Cloud Storage and Database',
        details: [
          'Exploring cloud storage solutions.',
          'Understanding object storage and file systems in the cloud.',
          'Introduction to cloud databases: SQL and NoSQL.',
          'Implementing scalable and reliable storage solutions.'
        ]
      },
      {
        number: 4,
        name: 'Networking and Security in the Cloud',
        details: [
          'Overview of networking concepts in cloud computing.',
          'Configuring virtual private clouds (VPC) and subnets.',
          'Implementing security measures in the cloud environment.',
          'Introduction to identity and access management (IAM).'
        ]
      },
      {
        number: 5,
        name: 'Cloud Deployment and Management',
        details: [
          'Deploying applications on the cloud platform.',
          'Managing cloud resources and scalability.',
          'Monitoring and optimizing cloud infrastructure.',
          'Introduction to DevOps tools for cloud deployments.'
        ]
      },
      {
        number: 6,
        name: 'Serverless Computing',
        details: [
          'Introduction to serverless computing.',
          'Building serverless functions using AWS Lambda.',
          'Developing serverless applications with Azure Functions.',
          'Exploring serverless functions in Google Cloud Platform.'
        ]
      },
      {
        number: 7,
        name: 'Cloud Cost Optimization',
        details: [
          'Understanding cloud cost optimization strategies.',
          'Analyzing pricing models and cost management tools.',
          'Optimizing cost usage and minimizing expenses.',
          'Best practices for cost-effective cloud deployments.'
        ]
      },
      {
        number: 8,
        name: 'Cloud Security and Compliance',
        details: [
          'Implementing cloud security best practices.',
          'Securing cloud infrastructure and data.',
          'Compliance frameworks and regulations in the cloud.',
          'Managing security controls for cloud environments.'
        ]
      }
    ]
  },
  fullstack: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Web Development',
        details: [
          'Overview of web development.',
          'Understanding client-side and server-side technologies.',
          'Introduction to HTML, CSS, and JavaScript.'
        ]
      },
      {
        number: 2,
        name: 'Building Responsive Web Pages',
        details: [
          'Creating HTML structure and layout.',
          'Styling web pages using CSS.',
          'Implementing responsive design principles.'
        ]
      },
      {
        number: 3,
        name: 'JavaScript Fundamentals',
        details: [
          'Basics of JavaScript programming.',
          'Working with variables, data types, and operators.',
          'Manipulating the DOM (Document Object Model).'
        ]
      },
      {
        number: 4,
        name: 'Front-End Development Tools',
        details: [
          'Introduction to front-end frameworks (e.g., Bootstrap).',
          'Utilizing code editors and browser developer tools.',
          'Version control with Git and GitHub.'
        ]
      },
      {
        number: 5,
        name: 'Back-End Development Basics',
        details: [
          'Introduction to back-end technologies (e.g., Node.js).',
          'Understanding server-side scripting.',
          'Handling HTTP requests and responses.'
        ]
      },
      {
        number: 6,
        name: 'Database Integration',
        details: [
          'Introduction to databases (e.g., MySQL, MongoDB).',
          'Querying and manipulating data using SQL or NoSQL.',
          'Connecting databases with web applications.'
        ]
      },
      {
        number: 7,
        name: 'Web Application Development',
        details: [
          'Building dynamic web applications using frameworks (e.g., Express.js).',
          'Implementing user authentication and authorization.',
          'Handling form submissions and data validation.'
        ]
      },
      {
        number: 8,
        name: 'Web Deployment and Maintenance',
        details: [
          'Deploying web applications to hosting platforms.',
          'Understanding website performance optimization.',
          'Basics of website security and maintenance.'
        ]
      }
    ]
  },
  hr: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Human Resource Management',
        details: [
          'Overview of HRM and its role in organizations.',
          'Key functions and responsibilities of HR professionals.',
          'Understanding HR policies and legal considerations.'
        ]
      },
      {
        number: 2,
        name: 'Recruitment and Selection',
        details: [
          'Introduction to the recruitment process.',
          'Job analysis and job descriptions.',
          'Effective sourcing and candidate screening techniques.',
          'Interviewing and selection methods.'
        ]
      },
      {
        number: 3,
        name: 'Employee Onboarding and Orientation',
        details: [
          'Importance of a comprehensive onboarding process.',
          'Preparing new employees for success.',
          'Orientation programs and integrating new hires into the organization.'
        ]
      },
      {
        number: 4,
        name: 'Performance Management',
        details: [
          'Setting performance expectations and goals.',
          'Performance appraisal methods and techniques.',
          'Providing feedback and conducting performance reviews.',
          'Performance improvement strategies.'
        ]
      },
      {
        number: 5,
        name: 'Training and Development',
        details: [
          'Identifying training needs and designing training programs.',
          'Different training methods and techniques.',
          'Evaluating training effectiveness and measuring ROI.'
        ]
      },
      {
        number: 6,
        name: 'Employee Relations and Engagement',
        details: [
          'Building positive employee relations.',
          'Employee communication strategies.',
          'Employee engagement initiatives and recognition programs.'
        ]
      },
      {
        number: 7,
        name: 'HR Metrics and Analytics',
        details: [
          'Understanding HR metrics and their importance.',
          'Data collection and analysis in HR.',
          'Using HR analytics to drive decision-making.'
        ]
      },
      {
        number: 8,
        name: 'Legal and Ethical Considerations in HR',
        details: [
          'Key employment laws and regulations.',
          'Ethical responsibilities of HR professionals.',
          'Handling employee relations issues ethically.'
        ]
      }
    ]
  },
  b_analytics: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Business Analytics',
        details: [
          'Overview of business analytics and its significance in decision-making.',
          'Introduction to data-driven decision-making and its benefits.',
          'Understanding the role of business analytics in driving business success.'
        ]
      },
      {
        number: 2,
        name: 'Data Collection and Preparation',
        details: [
          'Data collection methods and sources.',
          'Data cleaning and preprocessing techniques.',
          'Exploratory data analysis (EDA) to understand data patterns and trends.'
        ]
      },
      {
        number: 3,
        name: 'Descriptive Analytics',
        details: [
          'Introduction to descriptive analytics and its applications.',
          'Data visualization techniques for effective communication of insights.',
          'Statistical measures and analysis for summarizing data.'
        ]
      },
      {
        number: 4,
        name: 'Artificial Neural Networks',
        details: [
          'Introduction to Artificial Neural Networks (ANN).',
          'Understanding the architecture of a basic neural network.',
          'Training neural networks using backpropagation.',
          'Activation functions and loss functions in ANN.'
        ]
      },
      {
        number: 5,
        name: 'Predictive Analysis',
        details: [
          'Introduction to predictive analysis and its applications.',
          'Regression analysis: Linear regression and evaluation metrics.',
          'Classification analysis: Logistic regression and evaluation metrics.'
        ]
      }
    ]
  },
  digimar: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Digital Marketing',
        details: [
          'Overview of digital marketing and its importance.',
          'Digital marketing strategies and channels.',
          'Understanding the customer journey in the digital space.'
        ]
      },
      {
        number: 2,
        name: 'Social Media Marketing',
        details: [
          'Introduction to major social media platforms.',
          'Creating effective social media marketing strategies.',
          'Social media content creation and management.',
          'Social media advertising and campaign optimization.'
        ]
      },
      {
        number: 3,
        name: 'Search Engine Optimization (SEO)',
        details: [
          'Introduction to SEO and its significance.',
          'Keyword research and on-page optimization.',
          'Off-page optimization and link building techniques.',
          'Monitoring and improving website rankings.'
        ]
      },
      {
        number: 4,
        name: 'Content Marketing',
        details: [
          'Understanding the role of content in digital marketing.',
          'Creating compelling and engaging content.',
          'Content distribution and promotion strategies.',
          'Measuring content marketing success.'
        ]
      },
      {
        number: 5,
        name: 'Email Marketing',
        details: [
          'Importance of email marketing in digital campaigns.',
          'Building an effective email marketing strategy.',
          'Email campaign creation and optimization.',
          'Email automation and segmentation.'
        ]
      },
      {
        number: 6,
        name: 'Digital Analytics',
        details: [
          'Introduction to digital analytics tools and metrics.',
          'Measuring website traffic and user behavior.',
          'Analyzing marketing campaign performance.',
          'Reporting and data-driven decision making.'
        ]
      },
      {
        number: 7,
        name: 'Live Projects',
        details: [
          'Engage in two live digital marketing projects.',
          'Apply the acquired skills and knowledge in real-world scenarios.',
          'Gain hands-on experience and enhance practical expertise.'
        ]
      }
    ]
  },
  graphic: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Graphic Designing',
        details: [
          'Basics of graphic design principles and elements.',
          'Introduction to popular graphic design software.'
        ]
      },
      {
        number: 2,
        name: 'Typography and Layout Design',
        details: [
          'Understanding different font types and their uses.',
          'Creating visually appealing layouts for various mediums.'
        ]
      },
      {
        number: 3,
        name: 'Color Theory and Composition',
        details: [
          'Exploring colors and their impact on design.',
          'Creating balanced and harmonious compositions.'
        ]
      },
      {
        number: 4,
        name: 'Logo Design and Branding',
        details: [
          'Designing simple logos and understanding branding concepts.',
          'Creating cohesive brand identities.'
        ]
      },
      {
        number: 5,
        name: 'Digital Design for Web and Social Media',
        details: [
          'Designing graphics for websites and social media platforms.',
          'Optimizing designs for different screen sizes.'
        ]
      },
      {
        number: 6,
        name: 'Print Design and Publication Layouts',
        details: [
          'Designing for print mediums like brochures and posters.',
          'Creating attractive publication layouts.'
        ]
      },
      {
        number: 7,
        name: 'Image Editing and Photo Manipulation',
        details: [
          'Introduction to image editing tools.',
          'Enhancing photos and creating basic manipulations.'
        ]
      },
      {
        number: 8,
        name: 'Portfolio Development',
        details: [
          'Building a portfolio of design projects.',
          'Showcasing work effectively and preparing for job opportunities.'
        ]
      }
    ]
  },
  autocad: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to AutoCAD',
        details: [
          'Overview of AutoCAD interface and tools.',
          'Drawing and editing basic geometries.',
          'Working with layers and object properties.'
        ]
      },
      {
        number: 2,
        name: 'Advanced AutoCAD Techniques',
        details: [
          'Creating and modifying complex shapes.',
          'Using blocks and external references.',
          'Working with text, dimensions, and annotations.'
        ]
      },
      {
        number: 3,
        name: 'Introduction to Revit',
        details: [
          'Overview of Revit interface and project setup.',
          'Creating and modifying architectural elements.',
          'Working with views, sheets, and schedules.'
        ]
      },
      {
        number: 4,
        name: 'Revit for Structural Design',
        details: [
          'Creating structural elements and components.',
          'Understanding load calculations and analysis.',
          'Generating construction documentation.'
        ]
      },
      {
        number: 5,
        name: 'Revit for Structural Design',
        details: [
          'Creating structural elements and components.',
          'Understanding load calculations and analysis.',
          'Generating construction documentation.'
        ]
      },
      {
        number: 6,
        name: 'Advanced Revit Techniques',
        details: [
          'Family creation and customization.',
          'Collaboration and coordination tools.',
          'Rendering and visualization techniques.'
        ]
      },
      {
        number: 7,
        name: 'Project Work and Case Studies',
        details: [
          'Applying AutoCAD and Revit skills to real-world projects.',
          'Working on hands-on exercises and assignments.',
          'Reviewing case studies and best practices.'
        ]
      }
    ]
  },
  
    
  
  datascience: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Data Science',
        details: [
          'Overview of data science and its applications.',
          'Data acquisition and preprocessing techniques.',
          'Exploratory data analysis (EDA) using Python libraries like Pandas and NumPy.'
        ]
      },
      {
        number: 2,
        name: 'Data Visualization',
        details: [
          'Introduction to data visualization principles.',
          'Using Matplotlib and Seaborn for creating effective visualizations.',
          'Interactive data visualization with Plotly and Bokeh.'
        ]
      },
      {
        number: 3,
        name: 'Statistical Analysis',
        details: [
          'Fundamentals of statistics for data science.',
          'Hypothesis testing and confidence intervals.',
          'Regression analysis and ANOVA.'
        ]
      },
      {
        number: 4,
        name: 'Machine Learning',
        details: [
          'Supervised and unsupervised learning algorithms.',
          'Model evaluation and validation techniques.',
          'Feature engineering and selection.'
        ]
      },
      {
        number: 5,
        name: 'Big Data and Distributed Computing',
        details: [
          'Introduction to big data technologies like Hadoop and Spark.',
          'Processing and analyzing large datasets using distributed computing frameworks.',
          'Real-world case studies and applications.'
        ]
      }
    ]
  },
  nanoscience: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Nano Science',
        details: [
          'Overview of nano science and nanotechnology',
          'Fundamental concepts of nano materials and nanostructures',
          'Importance and applications of nano technology'
        ]
      },
      {
        number: 2,
        name: 'Nanomaterials Synthesis and Characterization',
        details: [
          'Techniques for synthesis of nanoparticles (e.g., chemical vapor deposition, sol-gel method)',
          'Characterization methods (e.g., scanning electron microscopy, transmission electron microscopy, atomic force microscopy)'
        ]
      },
      {
        number: 3,
        name: 'Properties of Nanostructured Materials',
        details: [
          'Mechanical, electrical, optical, and magnetic properties of nano materials',
          'Quantum confinement and surface effects in nanostructures'
        ]
      },
      {
        number: 4,
        name: 'Applications of Nanotechnology',
        details: [
          'Nanotechnology in electronics, medicine, energy, and environmental sectors',
          'Emerging trends and future prospects in nanotechnology'
        ]
      },
      {
        number: 5,
        name: 'Nanofabrication Techniques',
        details: [
          'Top-down and bottom-up fabrication methods',
          'Lithography techniques (e.g., photolithography, electron beam lithography)',
          'Nanopatterning and self-assembly processes'
        ]
      },
      {
        number: 6,
        name: 'Nanobiotechnology',
        details: [
          'Integration of nanotechnology with biology and medicine',
          'Applications of nanomaterials in drug delivery, imaging, and diagnostics'
        ]
      },
      {
        number: 7,
        name: 'Safety and Ethical Considerations in Nanotechnology',
        details: [
          'Health and environmental impacts of nanomaterials',
          'Regulatory frameworks and ethical guidelines in nano science research'
        ]
      },
      {
        number: 8,
        name: 'Live Projects (optional)',
        details: [
          'Engage in practical projects to apply concepts learned in the course',
          'Hands-on experience with nano fabrication techniques and characterization methods'
        ]
      }
    ]
  },

  englishspeaking: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to English Speaking',
        details: [
          'Basics of English pronunciation',
          'Introduction to common English phrases and greetings',
          'Importance of effective communication skills'
        ]
      },
      {
        number: 2,
        name: 'Building Vocabulary',
        details: [
          'Learning common words and phrases for daily conversations',
          'Techniques for expanding vocabulary through reading and listening',
          'Vocabulary exercises and drills'
        ]
      },
      {
        number: 3,
        name: 'Grammar Fundamentals',
        details: [
          'Understanding basic English grammar rules (tenses, articles, prepositions)',
          'Sentence structure and formation',
          'Practice exercises for grammar concepts'
        ]
      },
      {
        number: 4,
        name: 'Speaking Practice',
        details: [
          'Engaging in conversation simulations',
          'Role-playing exercises for various real-life scenarios (ordering food, making phone calls, etc.)',
          'Pronunciation drills and feedback sessions'
        ]
      },
      {
        number: 5,
        name: 'Listening Comprehension',
        details: [
          'Listening to spoken English in different accents and speeds',
          'Comprehension exercises to improve listening skills',
          'Techniques for understanding English speakers in various contexts'
        ]
      },
      {
        number: 6,
        name: 'Advanced Communication Skills',
        details: [
          'Advanced vocabulary and idiomatic expressions',
          'Strategies for effective public speaking and presentations',
          'Polite and assertive communication techniques'
        ]
      },
      {
        number: 7,
        name: 'Mock Interviews and Presentations',
        details: [
          'Practice sessions for job interviews and presentations',
          'Feedback and improvement suggestions from instructors',
          'Tips for confident and professional communication in professional settings'
        ]
      }
    ]
  },

  marketing: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Sales and Marketing',
        details: [
          'Understanding the fundamentals of sales and marketing',
          'Exploring the role of sales and marketing in business growth',
          'Differentiating between sales and marketing functions',
          'Overview of key concepts and strategies in sales and marketing'
        ]
      },
      {
        number: 2,
        name: 'Market Research and Customer Analysis',
        details: [
          'Conducting market research to identify customer needs and preferences',
          'Analyzing customer demographics, psychographics, and buying behavior',
          'Segmenting target markets and identifying ideal customer profiles',
          'Using data analytics tools for customer segmentation and analysis'
        ]
      },
      {
        number: 3,
        name: 'Sales Techniques and Strategies',
        details: [
          'Understanding the sales process: Prospecting, qualifying, presenting, and closing',
          'Developing effective sales pitches and presentations',
          'Handling objections and overcoming sales challenges',
          'Building long-term customer relationships and fostering loyalty'
        ]
      },
      {
        number: 4,
        name: 'Digital Marketing and Online Sales',
        details: [
          'Overview of digital marketing channels and strategies',
          'Leveraging social media platforms for lead generation and customer engagement',
          'Implementing email marketing campaigns and automation',
          'Optimizing websites and landing pages for conversion and sales'
        ]
      },
      {
        number: 5,
        name: 'Branding and Positioning',
        details: [
          'Creating a strong brand identity and value proposition',
          'Developing brand messaging and communication strategies',
          'Positioning products or services effectively in the market',
          'Managing brand reputation and customer perceptions'
        ]
      },
      {
        number: 6,
        name: 'Pricing and Promotion',
        details: [
          'Pricing strategies and tactics for different market segments',
          'Promotional techniques: Advertising, sales promotions, and public relations',
          'Planning and executing marketing campaigns to drive sales',
          'Measuring the effectiveness of promotions and ROI'
        ]
      },
      {
        number: 7,
        name: 'Sales Channel Management',
        details: [
          'Understanding different sales channels: Direct, indirect, online, and offline',
          'Selecting the right distribution channels based on product and market',
          'Managing channel partners and building strong channel relationships',
          'Evaluating channel performance and optimizing distribution strategies'
        ]
      },
      {
        number: 8,
        name: 'Sales Metrics and Performance Analysis',
        details: [
          'Key performance indicators (KPIs) for sales and marketing',
          'Using CRM systems to track and manage sales activities',
          'Analyzing sales data to identify trends, opportunities, and challenges',
          'Reporting and presenting sales insights to stakeholders'
        ]
      }
    ]
  },

  uiux: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to UI/UX Design',
        details: [
          'Understanding the fundamentals of user interface (UI) and user experience (UX) design.',
          'Overview of design principles, processes, and methodologies.',
          'Introduction to industry-standard tools and software.'
        ]
      },
      {
        number: 2,
        name: 'User Research and Analysis',
        details: [
          'Conducting user research to understand user needs, behaviors, and preferences.',
          'Analyzing data to identify user pain points and opportunities for improvement.',
          'Creating user personas and user journey maps.'
        ]
      },
      {
        number: 3,
        name: 'Wireframing and Prototyping',
        details: [
          'Introduction to wireframing tools and techniques.',
          'Creating low-fidelity wireframes to visualize layout and structure.',
          'Prototyping interactive designs to test usability and functionality.'
        ]
      },
      {
        number: 4,
        name: 'Visual Design Principles',
        details: [
          'Exploring typography, color theory, and visual hierarchy.',
          'Applying design principles to create aesthetically pleasing interfaces.',
          'Incorporating branding elements and visual consistency across designs.'
        ]
      },
      {
        number: 5,
        name: 'Usability Testing and Iteration',
        details: [
          'Conducting usability tests to gather feedback from users.',
          'Analyzing test results and identifying areas for improvement.',
          'Iterating on designs based on user feedback and usability findings.'
        ]
      },
      {
        number: 6,
        name: 'Responsive Design and Design Systems',
        details: [
          'Understanding the principles of responsive design for various devices and screen sizes.',
          'Creating design systems to maintain consistency and scalability across projects.',
          'Implementing best practices for designing accessible and inclusive interfaces.'
        ]
      },
      {
        number: 7,
        name: 'Portfolio Development and Career Preparation',
        details: [
          'Building a professional portfolio showcasing UI/UX design projects.',
          'Crafting resumes and cover letters tailored for UI/UX design roles.',
          'Exploring job search strategies and preparing for interviews in the UI/UX design field.'
        ]
      }
    ]
  },

  finance: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Finance',
        details: [
          'Understanding the basics of finance: definition, importance, and scope.',
          'Overview of financial markets, institutions, and instruments.',
          'Introduction to key financial concepts: time value of money, risk, return, and diversification.'
        ]
      },
      {
        number: 2,
        name: 'Financial Statements Analysis',
        details: [
          'Overview of financial statements: balance sheet, income statement, and cash flow statement.',
          'Analyzing financial statements to assess a company\'s financial health and performance.',
          'Ratio analysis: liquidity, solvency, profitability, and efficiency ratios.'
        ]
      },
      {
        number: 3,
        name: 'Financial Markets and Instruments',
        details: [
          'Understanding different types of financial markets: money market, capital market, and derivatives market.',
          'Introduction to financial instruments: stocks, bonds, mutual funds, ETFs, and options.',
          'Basics of investment strategies and portfolio management.'
        ]
      },
      {
        number: 4,
        name: 'Introduction to Corporate Finance',
        details: [
          'Overview of corporate finance: capital budgeting, capital structure, and working capital management.',
          'Evaluating investment opportunities using techniques like Net Present Value (NPV) and Internal Rate of Return (IRR).',
          'Understanding the cost of capital and optimal capital structure.'
        ]
      },
      {
        number: 5,
        name: 'Risk Management and Insurance',
        details: [
          'Basics of risk management: identifying, assessing, and managing financial risks.',
          'Introduction to insurance principles and types of insurance products.',
          'Understanding the role of insurance in personal finance and risk mitigation.'
        ]
      },
      {
        number: 6,
        name: 'Personal Finance and Budgeting',
        details: [
          'Basics of personal finance: budgeting, saving, investing, and retirement planning.',
          'Managing debt and credit effectively.',
          'Setting financial goals and creating a financial plan for the future.'
        ]
      },
      {
        number: 7,
        name: 'Introduction to Financial Modeling',
        details: [
          'Basics of financial modeling: principles, techniques, and best practices.',
          'Introduction to spreadsheet tools for financial modeling.',
          'Building simple financial models for forecasting and decision-making.'
        ]
      },
      {
        number: 8,
        name: 'Live Case Studies and Practical Applications',
        details: [
          'Engage in real-world case studies and practical exercises to apply learned concepts.',
          'Explore scenarios from various industries and sectors to understand their financial implications.'
        ]
      }
    ]
  },

  embedded: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Embedded Systems',
        details: [
          'Understanding the basics of embedded systems: definition, characteristics, and applications.',
          'Overview of microcontrollers, microprocessors, and embedded systems architecture.',
          'Introduction to embedded programming languages and development environments.'
        ]
      },
      {
        number: 2,
        name: 'Embedded Systems Hardware',
        details: [
          'Understanding hardware components of embedded systems: processors, memory, input/output interfaces.',
          'Basics of circuit design and schematic diagrams.',
          'Introduction to sensor and actuator interfacing.'
        ]
      },
      {
        number: 3,
        name: 'Embedded Systems Programming',
        details: [
          'Introduction to embedded programming languages: C, C++, and assembly language.',
          'Basics of firmware development: writing, compiling, and debugging embedded software.',
          'Hands-on exercises to develop simple embedded programs.'
        ]
      },
      {
        number: 4,
        name: 'Real-Time Operating Systems (RTOS)',
        details: [
          'Overview of real-time operating systems: characteristics, types, and features.',
          'Basics of task scheduling, synchronization, and inter-process communication.',
          'Introduction to RTOS programming and development tools.'
        ]
      },
      {
        number: 5,
        name: 'Embedded Systems Communication Protocols',
        details: [
          'Understanding communication protocols used in embedded systems: UART, SPI, I2C, and CAN.',
          'Basics of wireless communication protocols: Bluetooth, Wi-Fi, and Zigbee.',
          'Hands-on exercises to implement communication protocols in embedded systems.'
        ]
      },
      {
        number: 6,
        name: 'Embedded Systems Interfacing',
        details: [
          'Basics of interfacing external devices with embedded systems: sensors, actuators, displays.',
          'Introduction to peripheral interfaces: GPIO, ADC, PWM.',
          'Hands-on projects to interface sensors and actuators with microcontrollers.'
        ]
      },
      {
        number: 7,
        name: 'Embedded Systems Design and Development',
        details: [
          'Overview of embedded systems design methodologies: top-down vs. bottom-up approach.',
          'Basics of system design, architecture, and requirements analysis.',
          'Introduction to embedded software development lifecycle.'
        ]
      },
      {
        number: 8,
        name: 'Embedded Systems Applications and Case Studies',
        details: [
          'Explore real-world applications of embedded systems: automotive, consumer electronics, IoT, etc.',
          'Analysis of case studies highlighting successful embedded systems projects.',
          'Discussion and Q&A sessions to reinforce understanding and address queries.'
        ]
      }
    ]
  },

  iot: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Internet of Things (IoT)',
        details: [
          'Understanding IoT concepts and applications',
          'Overview of IoT architecture and components'
        ]
      },
      {
        number: 2,
        name: 'IoT Devices and Sensors',
        details: [
          'Types of IoT devices: sensors, actuators, and controllers',
          'Sensor technologies and their applications',
          'Hands-on experience with IoT devices'
        ]
      },
      {
        number: 3,
        name: 'Communication Protocols for IoT',
        details: [
          'Wired and wireless communication protocols (e.g., MQTT, HTTP, CoAP)',
          'IoT connectivity options: Wi-Fi, Bluetooth, Zigbee, LoRa, etc.',
          'Practical exercises on data transmission in IoT networks'
        ]
      },
      {
        number: 4,
        name: 'Data Processing and Analytics in IoT',
        details: [
          'Data collection, storage, and processing in IoT systems',
          'Introduction to edge computing and cloud computing in IoT',
          'Analyzing IoT data for insights and decision-making'
        ]
      },
      {
        number: 5,
        name: 'IoT Security and Privacy',
        details: [
          'Security challenges in IoT deployments',
          'Encryption, authentication, and access control in IoT systems',
          'Privacy considerations and compliance with regulations'
        ]
      },
      {
        number: 6,
        name: 'IoT Applications and Case Studies',
        details: [
          'Real-world IoT applications across various domains (e.g., smart cities, healthcare, agriculture)',
          'Case studies of successful IoT implementations',
          'Group projects to develop IoT solutions for specific use cases'
        ]
      },
      {
        number: 7,
        name: 'Hands-on Projects',
        details: [
          'Implementing IoT solutions using popular development platforms (e.g., Arduino, Raspberry Pi)',
          'Integrating sensors, actuators, and communication modules to build functional IoT prototypes'
        ]
      }
    ]
  },

  constructionplanning: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Construction Management',
        details: [
          'Overview of construction industry and its importance',
          'Roles and responsibilities of construction managers',
          'Introduction to project management principles in construction'
        ]
      },
      {
        number: 2,
        name: 'Project Planning and Scheduling',
        details: [
          'Understanding project scope, objectives, and constraints',
          'Techniques for project planning: Work Breakdown Structure (WBS), Gantt charts, Critical Path Method (CPM)',
          'Developing project schedules and timelines'
        ]
      },
      {
        number: 3,
        name: 'Cost Estimation and Budgeting',
        details: [
          'Basics of cost estimation in construction projects',
          'Factors influencing construction costs: materials, labor, equipment, overheads',
          'Budget preparation and cost control techniques'
        ]
      },
      {
        number: 4,
        name: 'Construction Methods and Technologies',
        details: [
          'Overview of construction methods: traditional, design-build, and modular construction',
          'Introduction to Building Information Modeling (BIM) and its applications',
          'Emerging construction technologies: drones, 3D printing, robotics'
        ]
      },
      {
        number: 5,
        name: 'Construction Contracts and Legal Aspects',
        details: [
          'Types of construction contracts: lump sum, cost-plus, unit price contracts',
          'Key contract terms and conditions',
          'Understanding legal issues and regulations in construction projects'
        ]
      },
      {
        number: 6,
        name: 'Quality Management in Construction',
        details: [
          'Importance of quality management in construction projects',
          'Quality planning, assurance, and control processes',
          'Implementing quality standards and best practices'
        ]
      },
      {
        number: 7,
        name: 'Health, Safety, and Environmental Management',
        details: [
          'Occupational health and safety regulations in construction',
          'Hazard identification, risk assessment, and mitigation strategies',
          'Environmental sustainability considerations in construction projects'
        ]
      },
      {
        number: 8,
        name: 'Project Monitoring and Control',
        details: [
          'Monitoring project progress and performance metrics',
          'Identifying deviations from the plan and taking corrective actions',
          'Project reporting and communication management'
        ]
      }
    ]
  },

  cardesign: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Automotive Design',
        details: [
          'Overview of automotive design principles and trends',
          'Roles and responsibilities of automotive designers',
          'Introduction to design process and methodology in car designing'
        ]
      },
      {
        number: 2,
        name: 'Fundamentals of Car Design',
        details: [
          'Understanding automotive aesthetics and ergonomics',
          'Design elements: proportions, lines, surfaces, and volumes',
          'Principles of form development and sketching techniques'
        ]
      },
      {
        number: 3,
        name: 'Digital Tools for Car Design',
        details: [
          'Introduction to digital design software: Autodesk Alias, Rhinoceros 3D',
          'Basics of Computer-Aided Design (CAD) for automotive applications',
          'Using digital sculpting tools for conceptual design and modeling'
        ]
      },
      {
        number: 4,
        name: 'Concept Development and Visualization',
        details: [
          'Techniques for ideation and concept generation',
          'Storyboarding and narrative development in automotive design',
          'Visualization methods: sketching, rendering, and 3D modeling'
        ]
      },
      {
        number: 5,
        name: 'Vehicle Packaging and Layout',
        details: [
          'Understanding vehicle architecture and packaging constraints',
          'Ergonomic considerations in car interior design',
          'Principles of seating, visibility, and spatial arrangement'
        ]
      },
      {
        number: 6,
        name: 'Exterior Design and Styling',
        details: [
          'Principles of exterior styling: aerodynamics, aesthetics, and branding',
          'Designing exterior elements: grille, headlights, body panels, and aerodynamic features',
          'Creating visual impact through surfacing and detailing'
        ]
      },
      {
        number: 7,
        name: 'Interior Design and Human Factors',
        details: [
          'Interior layout and space optimization',
          'Designing dashboard, center console, and instrument panel',
          'Integrating human-machine interface (HMI) and infotainment systems'
        ]
      },
      {
        number: 8,
        name: 'Advanced Visualization and Presentation',
        details: [
          'Advanced rendering techniques for photorealistic visualization',
          'Creating design presentations and mood boards',
          'Preparing design proposals for client or stakeholder review'
        ]
      }
    ]
  },

  geniticengineering: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Genetic Engineering',
        details: [
          'Overview of genetic engineering principles and applications',
          'Historical development and milestones in genetic engineering',
          'Ethical and regulatory considerations in genetic research'
        ]
      },
      {
        number: 2,
        name: 'Basics of Molecular Biology',
        details: [
          'Fundamentals of DNA structure and function',
          'Genetic code and gene expression mechanisms',
          'Techniques for DNA isolation, amplification, and analysis'
        ]
      },
      {
        number: 3,
        name: 'Genetic Modification Techniques',
        details: [
          'Introduction to recombinant DNA technology',
          'Cloning vectors and gene cloning methods',
          'Gene editing techniques: CRISPR-Cas9, TALENs, and ZFNs'
        ]
      },
      {
        number: 4,
        name: 'Genomic and Proteomic Analysis',
        details: [
          'Genome sequencing technologies: Sanger sequencing, next-generation sequencing (NGS)',
          'Transcriptomics, proteomics, and metabolomics approaches',
          'Bioinformatics tools for data analysis and interpretation'
        ]
      },
      {
        number: 5,
        name: 'Applications of Genetic Engineering',
        details: [
          'Genetic modification of organisms: plants, animals, and microorganisms',
          'Biomedical applications: gene therapy, personalized medicine, and vaccine development',
          'Agricultural biotechnology: genetically modified crops and livestock'
        ]
      },
      {
        number: 6,
        name: 'Synthetic Biology and Bioengineering',
        details: [
          'Principles of synthetic biology and metabolic engineering',
          'Designing synthetic gene circuits and biological systems',
          'Industrial applications of synthetic biology in biofuel production, bioremediation, and biomaterials'
        ]
      },
      {
        number: 7,
        name: 'Ethical and Social Implications',
        details: [
          'Ethical considerations in genetic research and biotechnology',
          'Social impact of genetic engineering on healthcare, agriculture, and society',
          'Public perception, regulatory frameworks, and policy implications'
        ]
      },
      {
        number: 8,
        name: 'Future Trends and Emerging Technologies',
        details: [
          'Advances in genetic engineering: CRISPR-based diagnostics, gene drives, and gene synthesis',
          'Integration of genetic engineering with other disciplines: nanotechnology, artificial intelligence, and robotics',
          'Career opportunities and professional development in genetic engineering'
        ]
      }
    ]
  },

  tableau:{
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Data Visualization',
        details: [
          'Overview of data visualization concepts and importance',
          'Introduction to Tableau and Power BI platforms',
          'Understanding different types of charts and graphs'
        ]
      },
      {
        number: 2,
        name: 'Getting Started with Tableau',
        details: [
          'Installing Tableau Desktop and setting up the environment',
          'Connecting to data sources: Excel, CSV, databases, etc.',
          'Creating basic visualizations: bar charts, line charts, scatter plots, etc.',
          "Introduction to Tableau's interface and navigation"
        ]
      },
      {
        number: 3,
        name: 'Advanced Tableau Techniques',
        details: [
          'Working with calculated fields and parameters',
          'Creating interactive dashboards and stories',
          'Implementing filters, hierarchies, and sets',
          'Using mapping techniques and geographic data'
        ]
      },
      {
        number: 4,
        name: 'Power BI Fundamentals',
        details: [
          'Installing Power BI Desktop and configuring settings',
          'Importing data into Power BI: Excel, CSV, SQL Server, etc.',
          'Building basic visualizations: column charts, pie charts, matrices, etc.',
          "Introduction to Power BI's interface and data modeling capabilities"
        ]
      },
      {
        number: 5,
        name: 'Power BI Advanced Features',
        details: [
          'Working with DAX (Data Analysis Expressions) for calculated columns and measures',
          'Creating interactive reports and dashboards with slicers and drill-through',
          'Implementing custom visuals and integrating with R and Python',
          'Publishing and sharing reports on the Power BI Service'
        ]
      },
      {
        number: 6,
        name: 'Integrating Tableau and Power BI',
        details: [
          'Exporting Tableau visualizations to Power BI',
          'Leveraging Power BI data sources in Tableau',
          'Comparing strengths and weaknesses of Tableau and Power BI',
          'Best practices for choosing the right tool for specific visualization tasks'
        ]
      },
      {
        number: 7,
        name: 'Data Storytelling and Presentation',
        details: [
          'Principles of effective data storytelling',
          'Techniques for designing compelling data narratives',
          'Presenting insights and recommendations using Tableau and Power BI visualizations',
          'Incorporating storytelling elements into dashboards and reports'
        ]
      },
      {
        number: 8,
        name: 'Real-world Projects and Case Studies',
        details: [
          'Hands-on projects to apply Tableau and Power BI skills in real-world scenarios',
          'Analyzing datasets and creating insightful visualizations',
          'Presenting findings and recommendations based on data analysis'
        ]
      }
    ]
  },

  sketchup:{
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to SketchUp',
        details: [
          'Overview of 3D modeling concepts and applications',
          'Introduction to SketchUp interface and navigation',
          'Basic tools and functionalities in SketchUp: drawing tools, navigation tools, entity info, etc.'
        ]
      },
      {
        number: 2,
        name: 'Getting Started with SketchUp',
        details: [
          'Setting up SketchUp environment and preferences',
          'Creating and manipulating basic shapes: lines, rectangles, circles, etc.',
          'Understanding groups and components',
          'Applying materials and textures to objects'
        ]
      },
      {
        number: 3,
        name: 'Advanced SketchUp Techniques',
        details: [
          'Working with layers and scenes for organizing models',
          'Utilizing advanced drawing tools: arcs, polygons, 3D text, etc.',
          'Using modifiers and plugins to enhance modeling capabilities',
          'Introduction to rendering and visualization techniques'
        ]
      },
      {
        number: 4,
        name: 'Modeling Architectural Elements',
        details: [
          'Building architectural elements: walls, doors, windows, roofs, etc.',
          'Creating interior spaces: rooms, furniture, fixtures, etc.',
          'Incorporating details and accents: moldings, trim, stairs, etc.',
          'Optimizing models for architectural presentations'
        ]
      },
      {
        number: 5,
        name: 'Landscape and Site Modeling',
        details: [
          'Designing outdoor environments: terrain, gardens, paths, etc.',
          'Adding natural elements: trees, shrubs, rocks, etc.',
          'Incorporating site context: roads, sidewalks, water features, etc.',
          "Using SketchUp's sandbox tools for terrain manipulation"
        ]
      },
      {
        number: 6,
        name: 'Furniture and Product Design',
        details: [
          'Modeling furniture pieces: chairs, tables, sofas, etc.',
          'Exploring product design concepts: electronic devices, appliances, etc.',
          'Applying textures and materials to product models',
          'Techniques for creating realistic product visualizations'
        ]
      },
      {
        number: 7,
        name: 'Advanced Modeling Techniques',
        details: [
          'Exploring advanced modeling workflows: organic shapes, complex forms, etc.',
          'Utilizing extensions and plugins for specialized modeling tasks',
          'Introduction to parametric modeling and dynamic components',
          'Tips and tricks for efficient and accurate modeling'
        ]
      },
      {
        number: 8,
        name: 'Real-world Projects and Case Studies',
        details: [
          'Hands-on projects to apply SketchUp skills in real-world scenarios',
          'Designing and modeling architectural projects, interior spaces, landscapes, etc.',
          'Presenting models and design concepts effectively'
        ]
      }
    ]
  },

  python:{
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Python',
        details: [
          'Overview of Python programming language',
          'Setting up Python environment (interpreter, IDE)',
          'Basic syntax and data types in Python',
          'Writing and executing Python scripts'
        ]
      },
      {
        number: 2,
        name: 'Python Data Structures',
        details: [
          'Lists, tuples, and dictionaries',
          'Sets and frozensets',
          'Understanding sequences and their methods',
          'Accessing and manipulating data in data structures'
        ]
      },
      {
        number: 3,
        name: 'Control Flow and Functions',
        details: [
          'Conditional statements: if, elif, else',
          'Looping structures: for loops, while loops',
          'Functions and function parameters',
          'Scope and lifetime of variables'
        ]
      },
      {
        number: 4,
        name: 'File Handling and Input/Output',
        details: [
          'Opening, reading, and writing files',
          'Working with different file formats (text files, CSV files)',
          'Error handling and exceptions',
          'Command-line arguments and input/output redirection'
        ]
      },
      {
        number: 5,
        name: 'Object-Oriented Programming (OOP) in Python',
        details: [
          'Introduction to object-oriented programming concepts',
          'Classes and objects',
          'Inheritance and polymorphism',
          'Encapsulation and abstraction'
        ]
      },
      {
        number: 6,
        name: 'Advanced Python Concepts',
        details: [
          'List comprehensions and generator expressions',
          'Lambda functions and functional programming',
          'Decorators and context managers',
          'Working with modules and packages'
        ]
      },
      {
        number: 7,
        name: 'Python Standard Library',
        details: [
          'Overview of the Python Standard Library',
          'Commonly used modules: os, sys, math, datetime, etc.',
          'Using built-in functions and modules for various tasks',
          'Exploring additional libraries and resources'
        ]
      },
      {
        number: 8,
        name: 'Real-world Projects and Case Studies',
        details: [
          'Hands-on projects to apply Python programming skills',
          'Building practical applications: web scraping, data analysis, automation, etc.',
          'Presenting projects and sharing code with others'
        ]
      }
    ]
  },
  java: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Java',
        details: [
          'Overview of Java programming language',
          'Setting up Java Development Kit (JDK) and Integrated Development Environment (IDE)',
          'Basic syntax and structure of Java programs',
          'Writing and executing Java applications'
        ]
      },
      {
        number: 2,
        name: 'Data Types and Variables',
        details: [
          'Primitive data types: int, double, char, boolean, etc.',
          'Reference data types: arrays, strings, objects',
          'Variable declaration, initialization, and scope',
          'Type casting and conversions'
        ]
      },
      {
        number: 3,
        name: 'Control Flow and Decision Making',
        details: [
          'Conditional statements: if, else if, else',
          'Switch-case statements',
          'Looping structures: for loops, while loops, do-while loops',
          'Control flow statements: break, continue'
        ]
      },
      {
        number: 4,
        name: 'Methods and Functions',
        details: [
          'Defining and calling methods',
          'Method parameters and return types',
          'Method overloading and overriding',
          'Passing arrays and objects to methods'
        ]
      },
      {
        number: 5,
        name: 'Object-Oriented Programming (OOP) in Java',
        details: [
          'Introduction to object-oriented programming concepts',
          'Classes and objects',
          'Inheritance and polymorphism',
          'Encapsulation and abstraction'
        ]
      },
      {
        number: 6,
        name: 'Exception Handling',
        details: [
          'Understanding exceptions and error handling',
          'Handling exceptions using try-catch blocks',
          'Multiple catch blocks and nested try statements',
          'Throwing and catching custom exceptions'
        ]
      },
      {
        number: 7,
        name: 'File Handling and Input/Output',
        details: [
          'Reading from and writing to files in Java',
          'Working with different file formats (text files, binary files)',
          'File input/output streams and readers/writers',
          'Error handling and closing file resources'
        ]
      },
      {
        number: 8,
        name: 'Java Standard Library',
        details: [
          'Overview of the Java Standard Library (Java API)',
          'Commonly used classes and packages: java.lang, java.util, java.io, etc.',
          'Using built-in classes and methods for various tasks',
          'Exploring additional libraries and resources'
        ]
      },
      {
        number: 9,
        name: 'GUI Programming with Swing',
        details: [
          'Introduction to Graphical User Interface (GUI) programming in Java',
          'Designing GUI components using Swing',
          'Handling events and event listeners',
          'Creating interactive desktop applications'
        ]
      }
    ]
  },

  androidapp:{
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to Android Development',
        details: [
          'Overview of the Android platform and ecosystem',
          'Understanding the Android development environment: Android Studio, SDK, and Emulator',
          'Setting up a development environment on Windows, macOS, or Linux',
          'Exploring the basic components of an Android app: Activities, Layouts, Views, and Intents'
        ]
      },
      {
        number: 2,
        name: 'User Interface Design',
        details: [
          'Designing user interfaces using XML and Android Studio Layout Editor',
          'Working with different layout types: Linear Layout, Relative Layout, and Constraint Layout',
          'Adding interactive elements: Buttons, Text Fields, ImageViews, and RecyclerViews',
          'Implementing responsive design and supporting different screen sizes and orientations'
        ]
      },
      {
        number: 3,
        name: 'Fundamentals of Java Programming',
        details: [
          'Introduction to Java programming language syntax and concepts',
          'Variables, data types, and operators in Java',
          'Control flow statements: If-else, switch-case, loops',
          'Methods, classes, and object-oriented programming principles'
        ]
      },
      {
        number: 4,
        name: 'Working with Activities and Fragments',
        details: [
          'Understanding the activity lifecycle: onCreate(), onStart(), onResume(), onPause(), onStop(), onDestroy()',
          'Creating and managing multiple activities in an Android app',
          'Implementing fragment-based UIs for modular and flexible designs',
          'Communicating between activities and fragments using Intents and Bundle'
        ]
      },
      {
        number: 5,
        name: 'Handling User Input and Navigation',
        details: [
          'Capturing user input with EditText, CheckBox, RadioButton, and Spinner',
          'Validating user input and displaying error messages',
          'Implementing navigation patterns: Navigation Drawer, TabLayout, and ViewPager',
          "Using Android's navigation components for simplified navigation setup"
        ]
      },
      {
        number: 6,
        name: 'Data Persistence and Storage',
        details: [
          'Storing app data using SharedPreferences for simple key-value pairs',
          'Working with SQLite databases for structured data storage',
          'Reading and writing files to internal and external storage',
          "Leveraging Android's Room Persistence Library for efficient database management"
        ]
      },
      {
        number: 7,
        name: 'Networking and Web Services',
        details: [
          'Making network requests using HttpURLConnection and Retrofit library',
          'Parsing JSON responses using Gson library',
          'Handling background tasks and asynchronous operations with AsyncTask and AsyncTaskLoader',
          'Consuming RESTful APIs and integrating data from web services into Android apps'
        ]
      },
      {
        number: 8,
        name: 'Introduction to Material Design and Advanced Topics',
        details: [
          'Exploring Material Design principles for creating visually appealing and intuitive UIs',
          "Using Android's support libraries for backward compatibility and feature enhancements",
          'Implementing advanced features such as push notifications, location-based services, and in-app purchases',
          'Strategies for debugging, testing, and optimizing Android apps for performance and user experience'
        ]
      }
    ]
  },
  autocadm: {
    price: 8500,
    modules: [
      {
        number: 1,
        name: 'Introduction to AutoCAD',
        details: [
          'Overview of AutoCAD software and its applications in mechanical engineering.',
          'User interface tour: workspace, ribbons, tool palettes, and command line.',
          'Basic drawing commands: line, circle, arc, rectangle, and polygon.'
        ]
      },
      {
        number: 2,
        name: 'Basic Drawing and Editing',
        details: [
          'Drawing precision: snap, grid, and object tracking.',
          'Editing tools: move, copy, rotate, scale, trim, extend, and fillet.',
          'Object properties: layers, colors, line types, and line weights.'
        ]
      },
      {
        number: 3,
        name: 'Advanced Drawing Techniques',
        details: [
          'Construction tools: offset, mirror, array, hatch, and gradient.',
          'Annotation tools: text, dimensions (linear, aligned, angular), and leaders.',
          'Blocks and attributes: creation, insertion, and management.'
        ]
      },
      {
        number: 4,
        name: '3D Modeling Fundamentals',
        details: [
          'Introduction to 3D modeling: wireframe, surface, and solid modeling.',
          'Creating 3D primitives: box, cylinder, cone, sphere, and torus.',
          'Modifying 3D objects: extrude, revolve, sweep, loft, and boolean operations.'
        ]
      },
      {
        number: 5,
        name: 'Advanced 3D Modeling',
        details: [
          'Creating complex objects: fillets, chamfers, shells, and blends.',
          'Concept of assemblies and constraints.',
          'Introduction to rendering: applying materials, lights, and cameras.'
        ]
      },
      {
        number: 6,
        name: 'Dimensioning and Detailing',
        details: [
          'Advanced dimensioning techniques: ordinate, baseline, and continued dimensions.',
          'Geometric dimensioning and tolerancing (GD&T) symbols.',
          'Adding annotations and symbols to convey design intent.'
        ]
      },
      {
        number: 7,
        name: 'Plotting and Printing',
        details: [
          'Setting up layouts and viewports.',
          'Configuring plot settings: page setup, plot styles, and plotter configurations.',
          'Printing and exporting drawings: PDF, DWG, and DWF formats.'
        ]
      }
    ]
  }
};

export default curriculumContent;
