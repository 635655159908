// pages/NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  );
};

export default NotFoundPage;
