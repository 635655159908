// components/Others.js
import React from 'react';



import './AboutOthers.css'; // Import the corresponding CSS file
import LearnPros from './LearnPros';
import RuddoAdv from './RuddoAdv';

const AboutOthers = () => {
  return (
    <div className="others-container-about">
      <LearnPros />
      <RuddoAdv />
    </div>
  );
}

export default AboutOthers;
