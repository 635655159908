import React from 'react';
import './HeroIntern.css';
import Spotlight from './Spotlight';
import InternOptions from './InternOptions';
import { AppProvider } from './Context';

const HeroIntern = () => {
  return (
    <div className='HeroIntern'>
      
      <Spotlight />
      
      <AppProvider>
      <InternOptions />
    </AppProvider>
      
    </div>
  );
}

export default HeroIntern;