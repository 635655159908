// HeroOptions.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HeroOptions.css'; 
import HeroBg from '../assets/option-bg.png';
import BigBall from '../assets/Asset 2.png';
import ExploreMore from '../assets/explore-domain.png';

const HeroOptions = () => {
  const navigate = useNavigate(); // use useNavigate hook instead of useHistory

  const navigateToInternshipPage = () => {
    navigate('/internship'); // use navigate function for navigation
    window.scrollTo(0, 0);
  };

  return (
    <div className="hero-options">
      <div className="round-image">
        <img src={BigBall} alt="globe" />
      </div>
      <div className="square-image">
        <img src={HeroBg} alt='bg'/>
      </div>
      
      <div className="internship" >
        <h1>Enter The Realm Of Virtual Internships</h1>
        <p>
          Whether you're looking to upskill, switch careers, or explore new
          interests, our diverse course options provide the guidance and support you
          need to chart your own course to success.
        </p>
        <button className='ExButton' onClick={navigateToInternshipPage}>
          
        </button>
      </div>
    </div>
  );
};

export default HeroOptions;
