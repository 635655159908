import React, { useRef } from 'react';
import ListCourses from './ListCourses';
import './Management.css';
import grid from '../assets/grid.png';
import binary from '../assets/binary.png';
import management from '../assets/Management.png';

const Management = () => {
  const listCoursesRef = useRef(null);

  const handleViewCoursesClick = () => {
    if (listCoursesRef.current) {
      listCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="management">
      <div className="content-management">
        <div className="text-container-management">
          <div className="title-management">Management</div>
          <div className="description-management">
          Enhance your leadership and strategic thinking with our Management courses, designed to develop your business acumen and drive organizational success.
          </div>
          <button className="round-button-management" onClick={handleViewCoursesClick}>View Courses</button>
        </div>
        <div className="image-container-management">
          <div className="stack-imgs-management">
            <img src={grid} alt="grid" />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={management} alt="management" />
          </div>
          <div className="scatter-around-management">
            {/* Add the fourth image here */}
          </div>
        </div>
      </div>
      <div ref={listCoursesRef}>
        <ListCourses expanded={false} />
      </div>
    </div>
  );
};

export default Management;
