import React from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';
import CourseHero from '../components/CourseHero';
import Journey from '../components/Journey';
import Curriculum from '../components/Curriculum';
import DemandCourses from '../components/DemandCourses';
import FAQ from '../components/FAQ';
import domainContent from '../components/DomainContent';
import curriculumContent from '../components/CurriculumContent'; // Import curriculumContent
import CourseResult from '../components/CourseResult';
import './DomainPage.css';

const DomainPage = () => {
  const { domain } = useParams(); // Access the route parameter 'domain'

  // Use the domain parameter to get the corresponding content from curriculumContent
  const selectedDomainContent2 = curriculumContent[domain];
  const selectedDomainContent1 = domainContent[domain];
  
  return (
    <div className="domain-page">
      {selectedDomainContent1 ? (
        // Render the DomainFace component with the selected domain content
        <div>
          <Navigation />
          {/* Pass selectedDomainContent to CourseHero */}
          <CourseHero domainContent={selectedDomainContent1} />
          <CourseResult/>
          <Journey />
          {/* Pass curriculumContent as a prop named curriculumContent */}
          <Curriculum curriculumContent={selectedDomainContent2} /> 
          <DemandCourses  domain={domain}/>
          <FAQ />
          <Subscribe />
          <Footer />
        </div>
      ) : (
        // Handle case when domain parameter is not recognized
        <div>Domain not found</div>
      )}
    </div>
  );
};

export default DomainPage;
