import React, { useState } from 'react';
import './HeroFace.css';
import heroImage from '../assets/time.png';
import MultiStepForm from './MultiStepForm'; // Import the MultiStepForm component

const HeroFace = () => {
  const [showForm, setShowForm] = useState(false);

  const handleEnrollClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="hero-face">
      {!showForm ? (
        <div className="content1">
          <div className="text-container1">
            <div className="Topic1">A platform where learning meets reality</div>
            <div className="Description">
              Whether you're looking to upskill, switch careers, or explore new interests, our diverse course options provide the guidance and support you need to chart your own course to success.
            </div>
            <br />
            <button className='EnButton-white1' onClick={handleEnrollClick}></button>
          </div>
          <div className="image-container-heroface">
            <img src={heroImage} alt="Hero" />
          </div>
        </div>
      ) : (
        <MultiStepForm onClose={handleCloseForm} />
      )}
    </div>
  );
}

export default HeroFace;
