import React, { useRef } from 'react';
import ListCourses from './ListCourses';
import './Mechanical.css';
import grid from '../assets/grid.png';
import binary from '../assets/binary.png';
import mechanical from '../assets/mechanical.png';

const Mechanical = () => {
  const listCoursesRef = useRef(null);

  const handleViewCoursesClick = () => {
    if (listCoursesRef.current) {
      listCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="mechanical">
      <div className="content-mechanical">
        <div className="text-container-mechanical">
          <div className="title-mechanical">Mechanical</div>
          <div className="description-mechanical">
          Master the principles of mechanics and engineering with our Mechanical courses, preparing you to design and build the future of machinery and automation.
          </div>
          <button className="round-button-mechanical" onClick={handleViewCoursesClick}>View Courses</button>
        </div>
        <div className="image-container-mechanical">
          <div className="stack-imgs-mechanical">
            <img src={grid} alt="grid" />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={mechanical} alt="mechanical" />
          </div>
          <div className="scatter-around-mechanical">
            {/* Add the fourth image here */}
          </div>
        </div>
      </div>
      <div ref={listCoursesRef}>
        <ListCourses expanded={false} />
      </div>
    </div>
  );
};

export default Mechanical;
