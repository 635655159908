import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dynamoDB, s3 } from '../awsConfig';  // Update the import path
import contactImage from '../assets/contact.png';
import './ContactHero.css';

const ContactHero = () => {
  const [selectedOption, setSelectedOption] = useState('enroll');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    whatsappNumber: '',
    department: '',
    interestedDomain: '',
    purpose: '',
    message: '',
    position: ''
  });
  const [resumeFile, setResumeFile] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({
      name: '',
      email: '',
      phoneNumber: '',
      whatsappNumber: '',
      department: '',
      interestedDomain: '',
      purpose: '',
      message: '',
      position: ''
    });
    setResumeFile(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (event) => {
    setResumeFile(event.target.files[0]);
  };

  const uploadResumeToS3 = async (file) => {
    const fileName = `${Date.now()}-${file.name}`;
    const params = {
      Bucket: 'ruddo-resume', // Replace with your bucket name
      Key: fileName,
      Body: file,
      ACL: 'public-read'
    };

    try {
      console.log('Uploading file:', fileName);
      const data = await s3.upload(params).promise();
      console.log('File uploaded successfully:', data);
      return data.Location; // Return the URL of the uploaded file
    } catch (error) {
      console.error('Error uploading resume:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let resumeFileUrl = ''; // To store the URL of the resume

    if (selectedOption === 'apply' && resumeFile) {
      try {
        resumeFileUrl = await uploadResumeToS3(resumeFile);
      } catch (error) {
        toast.error('Failed to upload resume');
        return;
      }
    }

    const data = selectedOption === 'enroll' ? {
      pk: uuidv4(), // Generate UUID for primary key
      name: formData.name,
      email: formData.email,
      phone_number: formData.phoneNumber,
      whatsapp_number: formData.whatsappNumber,
      department: formData.department,
      interested_domain: formData.interestedDomain,
      purpose: formData.purpose
    } : selectedOption === 'talk' ? {
      pk: uuidv4(), // Generate UUID for primary key
      name: formData.name,
      email: formData.email,
      message: formData.message
    } : {
      pk: uuidv4(), // Generate UUID for primary key
      name: formData.name,
      email: formData.email,
      phone_number: formData.phoneNumber,
      position: formData.position,
      resume_url: resumeFileUrl, // Store the URL of the resume
      message: formData.message
    };

    const params = {
      TableName: selectedOption === 'enroll' ? 'EnrollNow' : selectedOption === 'talk' ? 'Enquiry' : 'JobApplication',
      Item: data
    };

    try {
      console.log('Submitting data:', JSON.stringify(params, null, 2)); // Log the request parameters
      const response = await dynamoDB.put(params).promise();
      console.log('PutItem response:', JSON.stringify(response, null, 2)); // Log the response
      toast.success('Data submitted successfully!');
    } catch (error) {
      console.error('Error submitting data:', JSON.stringify(error, null, 2)); // Log the error details
      toast.error('There was an error submitting the data.');
    }
  };
  const styles = {
  width: '53vw',
  minWidth: 'fit-content'
};

const landscapeStyles = window.matchMedia("(orientation: landscape)").matches ? styles : {};

const elementStyle = {
  ...landscapeStyles,
  // Add other styles that should always be applied here
};

  return (
    <div className="contact-hero-container">
      <ToastContainer />
      <div className="contact-content">
        <div className="contact-text-container">
          <div className="contact-title">How can we help?</div>
          <div className="contact-description">
            Whether you're looking to upskill, switch careers, or explore new interests, our diverse course options provide the guidance and support you need.
          </div>
          <br />
          <div className="contact-options">
            <div className="contact-subtitle"><strong>What are you looking for?</strong></div>
            <div className="contact-subtitle">Please choose an option below.</div>
            <div className="contact-radio-options">
              <div className="radio-option">
                <input
                  type="radio"
                  id="enroll"
                  name="contact-option"
                  value="enroll"
                  checked={selectedOption === 'enroll'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="enroll">
                  <strong> Enroll now</strong>
                  <br />
                  Learn what you seek
                </label>
              </div>
              <div className="radio-option">
                <input
                  type="radio"
                  id="talk"
                  name="contact-option"
                  value="talk"
                  checked={selectedOption === 'talk'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="talk">
                  <strong> Talk to us</strong>
                  <br />
                  General Enquiry
                </label>
              </div>
              <div className="radio-option">
                <input
                  type="radio"
                  id="apply"
                  name="contact-option"
                  value="apply"
                  checked={selectedOption === 'apply'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="apply">
                  <strong> Apply for a job</strong>
                  <br />
                  Be a part of our team
                </label>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            {selectedOption === 'enroll' && (
              <div className="enroll-fields">
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
                <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleInputChange} required />
                <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleInputChange} required />
                <input type="tel" name="whatsappNumber" placeholder="Whatsapp Number" value={formData.whatsappNumber} onChange={handleInputChange} required />
                <input type="text" name="department" placeholder="Department" value={formData.department} onChange={handleInputChange} required />
                <input type="text" name="interestedDomain" placeholder="Interested Domain" value={formData.interestedDomain} onChange={handleInputChange} required />
                <div className="other-inputs">
                <textarea name="purpose" placeholder="Why do you need this course?" value={formData.purpose} onChange={handleInputChange}  required />
                </div>
                <div className="form-footer">
                  <div className="support-text">
                    Hate contact forms?<br />
                    <strong>Write to us: support@ruddo.in</strong>
                  </div>
                  <button type="submit" className="enroll-button">Enroll Now</button>
                </div>
              </div>
            )}
            {selectedOption === 'talk' && (
              <div className="talk-fields">
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
                <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleInputChange} required />
                <div className="other-inputs">
                <textarea name="message" placeholder="Message" value={formData.message} onChange={handleInputChange}  required />
                </div>
                <div className="form-footer">
                  <div className="support-text">
                    Hate contact forms?<br />
                    <strong>Write to us: support@ruddo.in</strong>
                  </div>
                  <button type="submit" className="send-message-button">Send Message</button>
                </div>
              </div>
            )}
            {selectedOption === 'apply' && (
              <div className="apply-fields">
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
                <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleInputChange} required />
                <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleInputChange} required />
                <input type="text" name="position" placeholder="Position" value={formData.position} onChange={handleInputChange} required />
                <div className="file-input-container">
                  <input type="file" id="cvUpload" accept=".pdf, .doc, .docx" onChange={handleFileChange} required />
                  <label htmlFor="cvUpload">Upload your CV</label>
                </div>
                <div className="other-inputs">
                <textarea name="message" placeholder="Why do you need this job?" value={formData.message} onChange={handleInputChange} required />
                </div>
                <div className="form-footer">
                  <div className="support-text">
                    Hate contact forms?<br />
                    <strong>Write to us: support@ruddo.in</strong>
                  </div>
                  <button type="submit" className="apply1-button">Apply Now</button>

                </div>
              </div>
            )}
          </form>
        </div>
        <div className="contact-image-container">
          <img src={contactImage} alt="Contact" />
        </div>
      </div>
    </div>
  );
};

export default ContactHero;

