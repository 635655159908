import React, { useRef } from 'react';
import ListCourses from './ListCourses';
import './ECEandEEE.css'; // Import CSS file
import grid from '../assets/grid.png';
import binary from '../assets/binary.png';
import EEEC from '../assets/ECE&EEE.png';

const ECEandEEE = () => {
  // Create a reference for the ListCourses component
  const listCoursesRef = useRef(null);

  // Function to handle the click event of the "View Courses" button
  const handleViewCoursesClick = () => {
    // Scroll to the ListCourses component
    if (listCoursesRef.current) {
      listCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="ece-and-eee">
      <div className="content-ece">
        <div className="text-container-ece">
          <div className="title-ece">ECE & EEE</div>
          <div className="description-ece">
          Explore the dynamic fields of Electronics and Electrical Engineering, where our courses blend theory and practice to power your career in technology and innovation.
          </div>
          <button className="round-button-ece" onClick={handleViewCoursesClick}>View Courses</button>
        </div>
        <div className="image-container-ece">
          <div className="stack-imgs-ece">
            <img src={grid} alt="grid" />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={EEEC} alt="EEEC" />
          </div>
        </div>
      </div>
      {/* Render the ListCourses component */}
      <div ref={listCoursesRef}>
        <ListCourses expanded={false} selectedCourse={'ECE & EEE'} />
      </div>
    </div>
  );
};

export default ECEandEEE;
