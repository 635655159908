// components/Hero.js
import React from 'react';
import './Hero.css';
import HeroFace from './HeroFace';
import HeroOptions from './HeroOptions';

const Hero = () => {
  return (
    <div className='hero'>
      
      <HeroFace />
      <br/>
      <br/>
      <HeroOptions />
      
    </div>
  );
}

export default Hero;
