// pages/Landing.js
import React from 'react';
import Navigation from '../components/Navigation';
import Hero from '../components/Hero';
import Others from '../components/Others';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';
import AboutHero from '../components/AboutHero';
import AboutOthers from '../components/AboutOthers';
import './about.css';

function About() {
  return (
    <div className='about'>
        
      <Navigation />
      <AboutHero />
      <AboutOthers />
      <Subscribe />
      <Footer />
      
    </div>
  );
}

export default About;