import React, { useState } from 'react';
import './MultiStepForm.css';
import logo from '../assets/Asset 4.png';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dynamoDB } from '../awsConfig';

const MultiStepForm = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contactNumber: '',
    choice1: '',
    choice2: '',
    areaOfInterest: '',
    subCourse: '',
    domainCompetency: '',
    reason: ''
  });

  const [errors, setErrors] = useState({});

  const areasOfInterest = [
    'Computer Science & IT',
    'Civil',
    'ECE & EEE',
    'Mechanical',
    'Bio Medical',
    'Management',
    'Skill Development'
  ];

  const subCourses = {
    'Computer Science & IT': [
      'Artificial Intelligence',
      'Data Science',
      'Machine Learning',
      'Cyber Security',
      'Python',
      'Cloud Computing',
      'Full Stack Development',
      'Java',
      'Android App Development'
    ],
    'Civil': [
      'Construction Planning and Management',
      'SketchUp',
      'AutoCad & Revit'
    ],
    'ECE & EEE': [
      'Embedded Systems',
      'Internet of Things (IoT)'
    ],
    'Mechanical': [
      'Car Designing',
      'AUTO CAD'
    ],
    'Bio Medical': [
      'Genetic Engineering',
      'Nano Science and Nano Technology'
    ],
    'Management': [
      'Finance',
      'Sales & Marketing',
      'HR',
      'Business Analytics',
      'Digital Marketing'
    ],
    'Skill Development': [
      'English Speaking',
      'Tableau and Power BI',
      'UI/UX Design',
      'Graphic Design'
    ]
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setErrors({
      ...errors,
      [e.target.name]: ''
    });
  };

  const handleChoice = (choice, key) => {
    setFormData({
      ...formData,
      [key]: choice
    });
    setErrors({
      ...errors,
      [key]: ''
    });
  };

  const validateStep = () => {
    let stepErrors = {};

    if (step === 1) {
      if (!formData.fullName) stepErrors.fullName = 'Full Name is required';
      if (!formData.email) stepErrors.email = 'Email Address is required';
      if (!formData.contactNumber) stepErrors.contactNumber = 'Contact Number is required';
    }

    if (step === 2) {
      if (!formData.choice1) stepErrors.choice1 = 'Please select what you currently do';
    }

    if (step === 3) {
      if (!formData.choice2) stepErrors.choice2 = 'Please select your goal';
    }

    if (step === 4) {
      if (!formData.areaOfInterest) stepErrors.areaOfInterest = 'Please select an area of interest';
      if (!formData.subCourse) stepErrors.subCourse = 'Please select a sub course';
    }

    if (step === 5) {
      if (!formData.domainCompetency) stepErrors.domainCompetency = 'Please select your competency level';
    }

    setErrors(stepErrors);

    return Object.keys(stepErrors).length === 0;
  };

  const nextStep = () => {
    if (validateStep()) {
      setStep(step + 1);
    } else {
      toast.error('Please fill in all required fields.');
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const item = {
      uuid: uuidv4(),
      ...formData,
    };

    const params = {
      TableName: 'MultiStepForm',
      Item: item,
    };

    try {
      console.log('Submitting data:', JSON.stringify(params, null, 2));
      const response = await dynamoDB.put(params).promise();
      console.log('PutItem response:', JSON.stringify(response, null, 2));
      toast.success('Data submitted successfully!');
      //onClose();  Close the form or show success message
    } catch (error) {
      console.error('Error submitting data:', JSON.stringify(error, null, 2));
      toast.error('There was an error submitting the data.');
    }
  };

  const getProgressPercentage = () => {
    const totalSteps = 6; // Update this based on the total number of steps
    return (step / totalSteps) * 100;
  };

  return (
    <div className="multi-step-form">
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <img src={logo} alt="Logo" className="form-logo" />
        {step > 1 && (
          <button type="button" className="back-button" onClick={prevStep}>
            &larr; Back
          </button>
        )}

        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${getProgressPercentage()}%` }}
          ></div>
        </div>
        {step === 1 && (
  <div>
    <div className="form-group">
      <label>Full Name</label>
      <div className="input-group">
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
        {errors.fullName && <span className="error">Name is required field</span>}
      </div>
    </div>

    <div className="form-group">
      <label>Email Address</label>
      <div className="input-group">
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {errors.email && <span className="error">Email is required field</span>}
      </div>
    </div>

    <div className="form-group">
      <label>Contact Number</label>
      <div className="input-group">
        <input
          type="text"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleChange}
          required
        />
        {errors.contactNumber && <span className="error">Contact is required field</span>}
      </div>
    </div>

    <button type="button" className="next-button" onClick={nextStep}>
      Next
    </button>
  </div>
)}

        {step === 2 && (
          <div>
            <label>What do you currently do?</label>
            <button
              type="button"
              className={`choice-button ${formData.choice1 === 'Student' ? 'selected' : ''}`}
              onClick={() => handleChoice('Student', 'choice1')}
            >
              Student
            </button>
            <button
              type="button"
              className={`choice-button ${formData.choice1 === 'Salaried Professional' ? 'selected' : ''}`}
              onClick={() => handleChoice('Salaried Professional', 'choice1')}
            >
              Salaried Professional
            </button>
            <button
              type="button"
              className={`choice-button ${formData.choice1 === 'Self Employed' ? 'selected' : ''}`}
              onClick={() => handleChoice('Self Employed', 'choice1')}
            >
              Self Employed
            </button>
            {errors.choice1 && <span className="error">{errors.choice1}</span>}

            <button type="button" className="next-button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}
        {step === 3 && (
          <div>
            <label>What is your goal for taking this program?</label>
            <button
              type="button"
              className={`choice-button ${formData.choice2 === 'College Internship' ? 'selected' : ''}`}
              onClick={() => handleChoice('College Internship', 'choice2')}
            >
              College Internship
            </button>
            <button
              type="button"
              className={`choice-button ${formData.choice2 === 'Upskilling' ? 'selected' : ''}`}
              onClick={() => handleChoice('Upskilling', 'choice2')}
            >
              Upskilling
            </button>
            <button
              type="button"
              className={`choice-button ${formData.choice2 === 'Career Transition' ? 'selected' : ''}`}
              onClick={() => handleChoice('Career Transition', 'choice2')}
            >
              Career Transition
            </button>
            <button
              type="button"
              className={`choice-button ${formData.choice2 === 'Others' ? 'selected' : ''}`}
              onClick={() => handleChoice('Others', 'choice2')}
            >
              Others
            </button>
            {errors.choice2 && <span className="error">{errors.choice2}</span>}

            <button type="button" className="next-button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}
        {step === 4 && (
          <div>
            <label>Choose Area interested in:</label>
            <select
              name="areaOfInterest"
              value={formData.areaOfInterest}
              onChange={handleChange}
              required
            >
              <option value="">Select an area</option>
              {areasOfInterest.map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
            </select>
            {errors.areaOfInterest && <span className="error">{errors.areaOfInterest}</span>}

            {formData.areaOfInterest && (
              <>
                <label>Choose Sub Course:</label>
                <select
                  name="subCourse"
                  value={formData.subCourse}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a sub course</option>
                  {subCourses[formData.areaOfInterest].map((subCourse, index) => (
                    <option key={index} value={subCourse}>
                      {subCourse}
                    </option>
                  ))}
                </select>
                {errors.subCourse && <span className="error">{errors.subCourse}</span>}
              </>
            )}

            <button type="button" className="next-button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}
        {step === 5 && (
          <div>
            <label>What is your competency in the selected domain?</label>
            <button
              type="button"
              className={`choice-button ${formData.domainCompetency === 'Beginner' ? 'selected' : ''}`}
              onClick={() => handleChoice('Beginner', 'domainCompetency')}
            >
              Beginner
            </button>
            <button
              type="button"
              className={`choice-button ${formData.domainCompetency === 'Intermediate' ? 'selected' : ''}`}
              onClick={() => handleChoice('Intermediate', 'domainCompetency')}
            >
              Intermediate
            </button>
            <button
              type="button"
              className={`choice-button ${formData.domainCompetency === 'Expert' ? 'selected' : ''}`}
              onClick={() => handleChoice('Expert', 'domainCompetency')}
            >
              Expert
            </button>
            {errors.domainCompetency && <span className="error">{errors.domainCompetency}</span>}

            <button type="button" className="next-button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}
        {step === 6 && (
          <div>
            <label>Why do you need this program?</label>
            <input
              type="text"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              required
            />
            {errors.reason && <span className="error">{errors.reason}</span>}

            <button type="submit" className="submit-button">Submit</button>
            <button className="submit-button" onClick={handleClose}>Exit</button>
          </div>
        )}
      </form>
      <button type="button" className="close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default MultiStepForm;
