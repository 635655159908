import React, { useRef } from 'react';
import ListCourses from './ListCourses';
import './SkillDevelop.css'; // Import CSS file
import grid from '../assets/grid.png';
import binary from '../assets/binary.png';
import skill from '../assets/Skill_Development.png';

const SkillDevelop = () => {
  // Create a reference for the ListCourses component
  const listCoursesRef = useRef(null);

  // Function to handle the click event of the "View Courses" button
  const handleViewCoursesClick = () => {
    // Scroll to the ListCourses component
    if (listCoursesRef.current) {
      listCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="skill-develop">
      <div className="content-skill">
        <div className="text-container-skill">
          <div className="title-skill">Skill Development</div>
          <div className="description-skill">
          Empower your career with our Skill Development courses, tailored to enhance your professional capabilities and keep you ahead in a competitive job market          </div>
          <button className="round-button-skill" onClick={handleViewCoursesClick}>View Courses</button>
        </div>
        <div className="image-container-skill">
          <div className="stack-imgs-skill">
            <img src={grid} alt="grid" />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={skill} alt="skill" />
          </div>
          <div className="scatter-around-skill">
            {/* Add any additional images here */}
          </div>
        </div>
      </div>
      {/* Render the ListCourses component */}
      <div ref={listCoursesRef}>
        <ListCourses expanded={false} selectedCourse={'Skill Development'} />
      </div>
    </div>
  );
};

export default SkillDevelop;
