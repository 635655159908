import AWS from 'aws-sdk';

AWS.config.update({
  region: 'ap-south-1',
  credentials: new AWS.CognitoIdentityCredentials({
    /*IdentityPoolId: 'ap-south-1:22daee0e-3ac7-42c3-b054-ff079cfa9247'*/
    IdentityPoolId: 'ap-south-1:ac1cec5d-3455-42df-8714-f7ac3f7475a1'
  })
});

export const dynamoDB = new AWS.DynamoDB.DocumentClient();
export const s3 = new AWS.S3();



//aws

