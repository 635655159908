import React, { useState } from 'react';
import './Footer.css';
import MultiStepForm from './MultiStepForm'; // Import the MultiStepForm component
import Modal from './Modal'; // Import the Modal component

const Footer = () => {
  const [showForm, setShowForm] = useState(false);

  const handleEnrollClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="footer">
      <div className="content">
        <div className="text-container11">
          <div className="Topic11" >Ready to get started?</div>
          <div className="Description" >
            We are always adding new courses and blogs to our website. Sign up for our newsletter to be the first to know about our latest offerings.
          </div>
          <br/>
          <button className='EnButton-blue' onClick={handleEnrollClick}></button>
          {showForm && (
            <Modal onClose={handleCloseForm}>
              <MultiStepForm onClose={handleCloseForm} />
            </Modal>
          )}
        </div>
        <div className="right-container">
          <div className="column">
            <h3>Company</h3>
            <ul>
              <li><a href="#">About</a></li>
              <li><a href="#">Programs</a></li>
              <li><a href="#">Blog</a></li>
              <li><a href="#">Terms</a></li>
              <li><a href="#">Privacy</a></li>
            </ul>
          </div>
          <div className="column">
            <h3>Courses</h3>
            <ul>
              <li><a href="#">About</a></li>
              <li><a href="#">Programs</a></li>
              <li><a href="#">Blog</a></li>
              <li><a href="#">Terms</a></li>
              <li><a href="#">Privacy</a></li>
            </ul>
          </div>
          <div className="column">
            <h3>Social</h3>
            <ul>
              <li><a href="#">Instagram</a></li>
              <li><a href="#">Facebook</a></li>
              <li><a href="#">Twitter</a></li>
              <li><a href="#">YouTube</a></li>
              <li><a href="#">LinkedIn</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
