import React, { useState } from 'react';
import './DomainFace.css';
import DownloadForm from './DownloadForm';
import MultiStepForm from './MultiStepForm';

const DomainFace = ({ domainContent }) => {
  const [formType, setFormType] = useState(null);

  const handleEnrollClick = () => {
    setFormType('enroll');
  };

  const handleDownloadClick = () => {
    setFormType('download');
  };

  const handleCloseForm = () => {
    setFormType(null);
  };

  const handleFormSubmit = () => {
    // Logic to download the curriculum PDF
    const link = document.createElement('a');
    link.href = domainContent.pdf;
    link.download = 'curriculum.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!domainContent || !domainContent.logos) {
    return null;
  }

  const excludedDomains = ['sketchup', 'geneticengineering', 'nanoscienceandnanotechnology', 'englishspeaking'];

  return (
    <div className='DD'>
      {!formType ? (
        <div className="domain-face">
          <div className="domain-content">
            <div className="domain-text-container">
              <div className="domain-topic">{domainContent.topic}</div>
              <div className="domain-description">{domainContent.description}</div>
              <br />
              <div className="button-container">
                <button className='domain-enroll-button-white' onClick={handleEnrollClick}></button>
                <button className='domain-download-curriculum' onClick={handleDownloadClick}>
                  Download Curriculum
                  <span className="arrow-down"></span>
                </button>
              </div>
            </div>
            <div className="domain-image-container-heroface">
              <img src={domainContent.heroImage} alt="domain-hero" className='courseface' />
            </div>
          </div>
          <br />
          {!excludedDomains.includes(domainContent.topic.toLowerCase().replace(/\s+/g, '')) && (
            <div className='down-learn'>
              <div className="learn-container">
                <div className="learn-heading">What You'll Learn</div>
              </div>
              <div className="logo-container">
                {domainContent.logos.concat(domainContent.logos).map((logo, index) => (
                  <img key={index} src={logo} alt={`Logo ${index % domainContent.logos.length}`} className="domain-logo" />
                ))}
              </div>
            </div>
          )}
        </div>
      ) : formType === 'download' ? (
        <DownloadForm domainContent={domainContent} onClose={handleCloseForm} onDownload={handleFormSubmit} />
      ) : (
        <MultiStepForm onClose={handleCloseForm} />
      )}
    </div>
  );
};

export default DomainFace;
