import React from 'react';
import Navigation from '../components/Navigation';
import HeroIntern from '../components/HeroIntern';
import ListCourses from '../components/ListCourses';
import PopularCourses from '../components/PopularCourses';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';
import { AppProvider } from '../components/Context';
import './InternshipPage.css';


function InternshipPage() {
  return (
    <div className='internshipPage'>
      <AppProvider>
        <Navigation />
        <HeroIntern />
        <ListCourses />
        <PopularCourses />
        <Subscribe />
        <Footer />
      </AppProvider>
    </div>
  );
}

export default InternshipPage;