// pages/Landing.js
import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import Hero from '../components/Hero';
import Others from '../components/Others';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';
import AboutHero from '../components/AboutHero';
import AboutOthers from '../components/AboutOthers';
import ComingSoon from '../components/ComingSoon';

function Blog() {
  const [data, setData] = useState([])
  useEffect(()=>{
    fetch('http://localhost:8081/users')
    .then(res => res.json())
    .then(data => setData(data))
    .catch(err => console.log(err));
  }, [])
  return (
    <div className='blog'>
        
      <Navigation />

      <ComingSoon/>
      
    </div>
  );
}

export default Blog;