import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const faqs = [
    {
      question: 'Are there any educational criteria involved for the courses?',
      answer: 'No, there is no any educational criteria or pre-qualification involved for admission in any internship course.'
    },
    {
      question: 'What are the timings of live sessions?',
      answer: '4 live sessions every week with fixed timings from 6pm- 8pm'
    },
    {
      question: 'Will I get access to recorded classes, if I miss any of live classes?',
      answer: 'Yes, you can access the recorded class from your LMS account'
    },
    {
      question: 'Are there any certificates provided for the course?',
      answer: 'Yes, you would be receiving certificates upon completion of the course.'
    },
    {
      question: 'Would I be working on any projects during the course?',
      answer: 'Yes, you would be working on two real time projects i.e. Minor project andcapstone project.'
    },
    {
      question: 'What should I do in case of technical issues?',
      answer: 'If you experience any technical snag, Contact support@ruddo.in'
    },
    {
      question: 'When will I receive the certificate for the course?',
      answer: 'Upon completion of the desired course, certificate will be mailed to the registered mail id and if required sent through post to the registered address.'
    },
    {
      question: 'Will I receive any stipend after completing Internship program?',
      answer: 'Yes, it completely depends on students’ performance.'
    }

    // Add more FAQs as needed
  ];

  const handleQuestionClick = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-heading">Frequently Asked Questions</div>
      <br />
      <br />
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${selectedQuestion === index ? 'active' : ''}`}
            onClick={() => handleQuestionClick(index)}
          >
            <div className="question">{faq.question}</div>
            {selectedQuestion === index && <div className="answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
