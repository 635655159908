import aiHero from '../assets/aihero.png';
import dsHero from '../assets/dshero.png';
import javaHero from '../assets/javahero.png';
import pythonHero from '../assets/pythonhero.png';
import fullstackHero from '../assets/fullstackhero.png';
import mlHero from '../assets/mlhero.png';
import cyberHero from '../assets/cyberHero.png';
import cloudHero from '../assets/cloudhero.png';
import androidHero from '../assets/androidhero.png';
import constructionHero from '../assets/constructionhero.png';
import sketchupHero from '../assets/sketchuphero.png';
import autocadHero from '../assets/autocadhero.png';
import embeddedHero from '../assets/embeddedhero.png';
import iotHero from '../assets/iothero.png';
import autocadMHero from '../assets/autocad-mhero.png';
import cardesignHero from '../assets/cardesignhero.png';
import geneticHero from '../assets/genetichero.png';
import nanoHero from '../assets/nanohero.png';
import financeHero from '../assets/financehero.png';
import marketingHero from '../assets/marketinghero.png';
import hrHero from '../assets/hrhero.png';
import b_analyticsHero from '../assets/b_analyticsHero.png';
import digimarHero from '../assets/digimarHero.png';
import tableauHero from '../assets/tableauHero.png';
import uiuxHero from '../assets/uiuxHero.png';
import graphicHero from '../assets/graphicHero.png';
import engHero from '../assets/engHero.png';


const courseContents = {
  aiContent: {
    image: aiHero,
    heading: 'Artificial Intelligence',
    description: 'Delve into the fascinating world of Artificial Intelligence with Ruddo\'s specialized course. From machine learning algorithms to natural language processing, explore the cutting-edge technologies and applications driving AI innovation.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/ai'
  },
  dsContent: {
    image: dsHero,
    heading: 'Data Science',
    description: 'Unlock the power of data with Ruddo\'s Data Science course. From data wrangling to predictive modeling, learn the essential skills and techniques needed to extract actionable insights from complex datasets and drive informed decision-making.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/datascience'
  },
  mlContent: {
    image: mlHero,
    heading: 'Machine Learning',
    description: 'Unlock the power of Machine Learning with Ruddo\'s comprehensive course. Dive into the intricacies of predictive modeling, neural networks, and deep learning to understand and apply the algorithms that are revolutionizing data analysis and automation.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/ml'
  },
  cyberContent: {
    image: cyberHero,
    heading: 'Cyber Security',
    description: 'Secure the digital world with Ruddo\'s advanced Cyber Security course. Learn about threat detection, risk management, and defense mechanisms to protect systems and data from cyber threats and vulnerabilities, ensuring robust security in an increasingly connected world.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/cyber'
  },
  pythonContent: {
    image: pythonHero,
    heading: 'Python Programming',
    description: 'Unlock the power of programming with Ruddo\'s Python course. Whether you\'re a seasoned developer or just starting out, dive into the versatile Python language and discover how its simplicity and flexibility can empower you to tackle a wide range of coding challenges.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/python'
  },
  cloudContent: {
    image: cloudHero,
    heading: 'Cloud Computing',
    description: 'Master the future of IT infrastructure with Ruddo\'s Cloud Computing course. Discover the principles of cloud services, including IaaS, PaaS, and SaaS, and learn to deploy, manage, and optimize cloud environments for scalable and efficient operations.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/cloud'
  },
  javaContent: {
    image: javaHero,
    heading: 'Java Programming',
    description: 'Embark on a journey into the world of Java programming with Ruddo\'s comprehensive course. From building robust applications to crafting scalable web services, learn the language and tools that power some of the world\'s most innovative software solutions.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/java'
  },
  androidContent: {
    image: androidHero,
    heading: 'Android App Development',
    description: 'Dive into the exciting world of Android app development with Ruddo\'s comprehensive course. From designing user interfaces to implementing functionality, learn to build feature-rich apps that delight users and drive engagement.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/androidapp'
  },
  fullstackContent: {
    image: fullstackHero,
    heading: 'Full Stack Development',
    description: 'Master the art of full stack development with Ruddo\'s comprehensive course. From frontend frameworks to backend technologies, gain the skills and knowledge needed to create dynamic web applications that deliver exceptional user experiences.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/fullstack'
  },
  constructionContent: {
    image: constructionHero,
    heading: 'Construction Planning and Management',
    description: 'Build the future with Ruddo\'s Construction Planning and Management course. From initial project planning to on-site execution, gain the skills and knowledge needed to oversee construction projects efficiently, effectively, and safely.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/constructionplanning'
  },
  sketchupContent: {
    image: sketchupHero,
    heading: 'SketchUp',
    description: 'Bring your designs to life with Ruddo\'s SketchUp course. Whether you\'re an aspiring architect, interior designer, or hobbyist, learn to create stunning 3D models and visualizations that captivate audiences and transform your ideas into reality.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/sketchup'
  },
  autocadContent: {
    image: autocadHero,
    heading: 'AutoCAD',
    description: 'Enter the realm of precision engineering with Ruddo\'s AutoCAD Mechanical Engineering course. From intricate designs to seamless simulations, delve into the world of Computer-Aided Design (CAD) and master the tools essential for creating cutting-edge solutions in mechanical engineering.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/autocad'
  },
  autocadMContent: {
    image: autocadMHero,
    heading: 'AutoCAD Mechanical',
    description: 'Enter the realm of precision engineering with Ruddo\'s AutoCAD Mechanical Engineering course. From intricate designs to seamless simulations, delve into the world of Computer-Aided Design (CAD) and master the tools essential for creating cutting-edge solutions in mechanical engineering.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/autocadm'
  },
  embeddedContent: {
    image: embeddedHero,
    heading: 'Embedded Systems',
    description: 'Embark on a journey into the heart of technology with Ruddo\'s embedded systems course. Explore the intricate world of hardware and software integration, and learn to design and deploy cutting-edge embedded solutions that power the devices shaping our modern world.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/embedded'
  },
  iotContent: {
    image: iotHero,
    heading: 'Internet of Things (IoT)',
    description: 'Connect to the future with Ruddo\'s IoT course. Explore the vast network of interconnected devices, learn to harness the power of data-driven insights, and discover how IoT is revolutionizing industries and transforming the way we live and work.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/iot'
  },
  cardesignContent: {
    image: cardesignHero,
    heading: 'Car Designing',
    description: 'Rev up your creativity and dive into the exciting world of car design with Ruddo\'s specialized course. From sleek sports cars to futuristic electric vehicles, explore the art and science behind crafting the automobiles of tomorrow.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/cardesign'
  },
  geneticContent: {
    image: geneticHero,
    heading: 'Genetic Engineering',
    description: 'Uncover the secrets of life itself with Ruddo\'s Genetic Engineering course. From modifying DNA to create new organisms to revolutionizing healthcare with gene therapies, dive into the world of genetic manipulation and explore its potential to reshape our future.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/geneticengineering'
  },
  nanoContent: {
    image: nanoHero,
    heading: 'Nano Science and Nano Technology',
    description: 'Explore the fascinating world of nano-scale materials and technologies with Ruddo\'s Nano Science and Nano Technology course. Delve into the realm of atoms and molecules, and discover how these tiny structures hold the key to groundbreaking innovations in science and technology.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/nanoscience'
  },
  marketingContent: {
    image: marketingHero,
    heading: 'Marketing',
    description: 'Master the art and science of persuasion with Ruddo\'s Marketing course. From crafting compelling campaigns to analyzing consumer behavior, learn the strategies and techniques that drive successful marketing initiatives in today\'s dynamic business landscape.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/marketing'
  },
  financeContent: {
    image: financeHero,
    heading: 'Finance',
    description: 'Take control of your financial future with Ruddo\'s comprehensive finance course. Whether you\'re aiming to navigate the stock market, manage personal finances, or make strategic business decisions, this course equips you with the knowledge and skills needed to thrive in the world of finance.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/finance'
  },
  hrContent: {
    image: hrHero,
    heading: 'Human Resources',
    description: 'Transform organizational effectiveness with Ruddo\'s Human Resources course. Gain expertise in talent management, employee relations, and strategic HR practices to foster a productive and positive workplace environment, driving business success through people management.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/hr'
  },
  bAnalyticsContent: {
    image: b_analyticsHero,
    heading: 'Business Analytics',
    description: 'Harness the power of data with Ruddo\'s Business Analytics course. Explore data-driven decision-making, statistical analysis, and data visualization to uncover insights and drive strategic business outcomes in today\'s data-centric world.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/b_analytics'
  },
  digimarContent: {
    image: digimarHero,
    heading: 'Digital Marketing',
    description: 'Navigate the digital landscape with Ruddo\'s Digital Marketing course. From SEO and content marketing to social media and PPC campaigns, learn the tactics and strategies that drive online engagement, brand visibility, and digital growth.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/digimar'
  },
  englishContent: {
    image: engHero,
    heading: 'English Speaking',
    description: 'Refine your spoken English skills with Ruddo\'s English Speaking course. From honing conversational fluency to mastering pronunciation, join us on a transformative journey towards confident and articulate communication.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/englishspeaking' 
  },
  tableauContent: {
    image: tableauHero,
    heading: 'Tableau and Power BI',
    description: 'Unlock the power of data visualization with Ruddo\'s Tableau and Power BI course. From raw data to actionable insights, master the tools and techniques needed to create compelling visualizations that drive informed decision-making and empower businesses to thrive.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/tableau'  
  },
  uiuxContent: {
    image: uiuxHero,
    heading: 'UI/UX Design',
    description: 'Step into the realm of user-centric design with Ruddo\'s UI/UX course. From intuitive app interfaces to engaging website experiences, uncover the principles and practices behind creating digital products that captivate users and drive success.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/uiux'    
  },
  graphicContent: {
    image: graphicHero,
    heading: 'Graphic Design',
    description: 'Unleash your creativity with Ruddo\'s Graphic Design course. Study design principles, typography, and digital tools to create visually stunning graphics for branding, advertising, and multimedia projects, turning your artistic vision into reality.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/graphic'     
  }
};


const demandCoursesContent = {
  ai: [
    courseContents.dsContent,
    courseContents.mlContent,
    courseContents.cyberContent,
    courseContents.pythonContent,
    courseContents.cloudContent,
    courseContents.fullstackContent,
    courseContents.javaContent,
    courseContents.androidContent
  ],
  datascience: [
    courseContents.aiContent,
    courseContents.mlContent,
    courseContents.cyberContent,
    courseContents.pythonContent,
    courseContents.cloudContent,
    courseContents.fullstackContent,
    courseContents.javaContent,
    courseContents.androidContent
  ],
  ml: [
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.cyberContent,
    courseContents.pythonContent,
    courseContents.cloudContent,
    courseContents.fullstackContent,
    courseContents.javaContent,
    courseContents.androidContent  
  ],
  cyber:[
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.mlContent,
    courseContents.pythonContent,
    courseContents.cloudContent,
    courseContents.fullstackContent,
    courseContents.javaContent,
    courseContents.androidContent
  ],
  python:[
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.mlContent,
    courseContents.cyberContent,
    courseContents.cloudContent,
    courseContents.fullstackContent,
    courseContents.javaContent,
    courseContents.androidContent
  ],
  cloud: [
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.mlContent,
    courseContents.cyberContent,
    courseContents.pythonContent,
    courseContents.fullstackContent,
    courseContents.javaContent,
    courseContents.androidContent
  ],
  fullstack:[
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.mlContent,
    courseContents.cyberContent,
    courseContents.pythonContent,
    courseContents.cloudContent,
    courseContents.javaContent,
    courseContents.androidContent
  ],
  java:[
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.mlContent,
    courseContents.cyberContent,
    courseContents.pythonContent,
    courseContents.cloudContent,
    courseContents.fullstackContent,
    courseContents.androidContent
  ],
  androidapp:[
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.mlContent,
    courseContents.cyberContent,
    courseContents.pythonContent,
    courseContents.cloudContent,
    courseContents.fullstackContent,
    courseContents.javaContent
  ],
  constructionplanning:[
    courseContents.sketchupContent,
    courseContents.autocadContent,
    courseContents.autocadMContent
  ],
  sketchup: [
    courseContents.autocadContent,
    courseContents.constructionContent,
    courseContents.autocadMContent
  ],
  autocad: [
    courseContents.autocadMContent,
    courseContents.constructionContent,
    courseContents.sketchupContent
  ],
  embedded: [
    courseContents.iotContent,
    courseContents.pythonContent,
    courseContents.javaContent
  ],
  iot: [
    courseContents.embeddedContent,
    courseContents.pythonContent,
    courseContents.javaContent,
    courseContents.aiContent,
    courseContents.dsContent,
    courseContents.mlContent
  ],
  cardesign: [
    courseContents.autocadMContent,
    courseContents.autocadContent,
    courseContents.iotContent,
    courseContents.embeddedContent
  ],
  autocadm: [
    courseContents.autocadContent,
    courseContents.iotContent,
    courseContents.embeddedContent,
    courseContents.cardesignContent
  ],
  nanoscience: [
    courseContents.geneticContent,
    courseContents.pythonContent,
    courseContents.mlContent
  ],
  geniticengineering: [
    courseContents.nanoContent,
    courseContents.pythonContent,
    courseContents.mlContent
  ],
  finance:[
    courseContents.marketingContent,
    courseContents.hrContent,
    courseContents.bAnalyticsContent,
    courseContents.digimarContent
  ],
  marketing: [
    courseContents.financeContent,
    courseContents.hrContent,
    courseContents.bAnalyticsContent,
    courseContents.digimarContent
  ],
  hr: [
    courseContents.financeContent,
    courseContents.marketingContent,
    courseContents.bAnalyticsContent,
    courseContents.digimarContent
  ],
  b_analytics: [
    courseContents.financeContent,
    courseContents.marketingContent,
    courseContents.digimarContent,
    courseContents.hrContent   
  ],
  digimar: [
    courseContents.financeContent,
    courseContents.hrContent,
    courseContents.bAnalyticsContent,
    courseContents.marketingContent,
    courseContents.tableauContent,
    courseContents.uiuxContent,
    courseContents.graphicContent,
    courseContents.englishContent
  ],
  englishspeaking: [
    courseContents.tableauContent,
    courseContents.uiuxContent,
    courseContents.graphicContent,
    courseContents.digimarContent
  ],
  tableau: [
    courseContents.englishContent,
    courseContents.uiuxContent,
    courseContents.graphicContent,
    courseContents.digimarContent
  ],
  uiuxHero: [
    courseContents.tableauContent,
    courseContents.englishContent,
    courseContents.graphicContent,
    courseContents.digimarContent
  ],
  graphic: [
    courseContents.tableauContent,
    courseContents.uiuxContent,
    courseContents.englishContent,
    courseContents.digimarContent
  ]
};


export default demandCoursesContent;

