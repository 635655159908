// DomainContent.js
import aiHero from '../assets/aihero.png';
import dsHero from '../assets/dshero.png';
import javaHero from '../assets/javahero.png';
import pythonHero from '../assets/pythonhero.png';
import fullstackHero from '../assets/fullstackhero.png';
import mlHero from '../assets/mlhero.png';
import cyberHero from '../assets/cyberHero.png';
import cloudHero from '../assets/cloudhero.png';
import androidHero from '../assets/androidhero.png';
import constructionHero from '../assets/constructionhero.png';
import sketchupHero from '../assets/sketchuphero.png';
import autocadHero from '../assets/autocadhero.png';
import embeddedHero from '../assets/embeddedhero.png';
import iotHero from '../assets/iothero.png';
import autocadMHero from '../assets/autocad-mhero.png';
import cardesignHero from '../assets/cardesignhero.png';
import geneticHero from '../assets/genetichero.png';
import nanoHero from '../assets/nanohero.png';
import financeHero from '../assets/financehero.png';
import marketingHero from '../assets/marketinghero.png';
import hrHero from '../assets/hrhero.png';
import b_analyticsHero from '../assets/b_analyticsHero.png';
import digimarHero from '../assets/digimarHero.png';
import tableauHero from '../assets/tableauHero.png';
import uiuxHero from '../assets/uiuxHero.png';
import graphicHero from '../assets/graphicHero.png';
import engHero from '../assets/engHero.png';









import numpyLogo from '../assets/numpy.png';
import pandasLogo from '../assets/pandas.png';
import kerasLogo from '../assets/keras.png';
import scilearnLogo from '../assets/scilearn.png';
import tensorflowLogo from '../assets/tensorflow.png';
import pythonlogo from '../assets/pythonicon.png';
import mysqllogo from '../assets/mysql.png';
import powerbilogo from '../assets/powerbi.png';
import netbeanslogo from '../assets/netbeans.png';
import eclipselogo from '../assets/eclipse.png';
import ijlogo from '../assets/ij.png';
import jdklogo from '../assets/jdk.png';
import mavenlogo from '../assets/maven.png';
import junitlogo from '../assets/junit.png';
import matplotliblogo from '../assets/matplotlib.png';
import vscodelogo from '../assets/vscode.png';
import pycharmlogo from '../assets/pycharm.png';
import jupyterlogo from '../assets/jupyter.png';
import anacondalogo from '../assets/anaconda.png';
import mongodblogo from '../assets/mongodb.png';
import javascriptlogo from '../assets/javascript.png';
import csslogo from '../assets/css.png';
import htmllogo from '../assets/html.png';
import githublogo from '../assets/github.png';
import burplogo from '../assets/burp.png';
import metaspotlogo from '../assets/metaspot.png';
import nessuslogo from '../assets/nessus.png';
import snortlogo from '../assets/snort.png';
import wiresharklogo from '../assets/wireshark.png';
import gcplogo from '../assets/gcp.png';
import awslogo from '../assets/aws.png';
import azurelogo from '../assets/azure.png';
import canarylogo from '../assets/canary.png';
import gradlelogo from '../assets/gradle.png';
import retrofitlogo from '../assets/retrofit.png';
import studiologo from '../assets/studio.png';
import firebaselogo from '../assets/firebase.png';
import glidelogo from '../assets/glide.png';
import bluebeamlogo from '../assets/bluebeam.png';
import revitlogo from '../assets/revit-l.png';
import sagelogo from '../assets/sage.png';
import procorelogo from '../assets/procore.png';
import oraclelogo from '../assets/oracle.png';
import plangridlogo from '../assets/plangrid.png';
import aconexlogo from '../assets/aconex.png';
import autocadlogo from '../assets/autocad.png';
import simulinklogo from '../assets/simulink.png';
import arduinologo from '../assets/arduino.png';
import raspberrypilogo from '../assets/raspberrypi.png';
import proteuslogo from '../assets/proteus.png';
import matlablogo from '../assets/matlab.png';
import cpplogo from '../assets/c++.png';
import thinkspeaklogo from '../assets/thinkspeak.png';
import tflitelogo from '../assets/tflite.png';
import awsiotlogo from '../assets/awsiot.png';
import noderedlogo from '../assets/nodered.png';
import mqttlogo from '../assets/mqtt.png';
import azureiotlogo from '../assets/azureiot.png';
import ibmlogo from '../assets/ibm.png';
import rhinologo from '../assets/rhino.png';
import aliaslogo from '../assets/alias.png';
import vredlogo from '../assets/vred.png';
import solidworkslogo from '../assets/solidworks.png';
import blenderlogo from '../assets/blender.png';
import sketchbooklogo from '../assets/sketchbook.png';
import photoshoplogo from '../assets/photoshop.png';
import gravitysketchlogo from '../assets/gravitysketch.png';
import keyshotlogo from '../assets/keyshot.png';
import sqllogo from '../assets/sql.png';
import gglanalyticslogo from '../assets/gglanalytics.png';
import excellogo from '../assets/excel.png';
import tableaulogo from '../assets/tableau.png';
import tflogo from '../assets/tf.png';
import salesforcelogo from '../assets/salesforce.png';
import salesloftlogo from '../assets/salesloft.png';
import crmlogo from '../assets/crm.png';
import pipedrivelogo from '../assets/pipedrive.png';
import hubspotlogo from '../assets/hubspot.png';
import mailchimplogo from '../assets/mailchimp.png';
import rlogo from '../assets/r.png';
import hr1logo from '../assets/hr1.png';
import hr2logo from '../assets/hr2.png';
import hr3logo from '../assets/hr3.png';
import hr4logo from '../assets/hr4.png';
import hr5logo from '../assets/hr5.png';
import hr6logo from '../assets/hr6.png';
import hr7logo from '../assets/hr7.png';
import hr8logo from '../assets/hr8.png';
import ggladlogo from '../assets/gglad.png';
import adsenselogo from '../assets/adsense.png';
import cloud_plotlogo from '../assets/cloud_plot.png';
import tableau_plogo from '../assets/tableau_p.png';
import XDlogo from '../assets/XD.png';
import figmalogo from '../assets/figma.png';
import creativelogo from '../assets/creative.png';
import invisionlogo from '../assets/invision.png';
import balsamiqlogo from '../assets/balsamiq.png';
import marvellogo from '../assets/marvel.png';
import Adobe_Ailogo from '../assets/Adobe_Ai.png';
import Pslogo from '../assets/Ps.png';
import Idlogo from '../assets/Id.png';
import aiPDF from '../assets/curiculum/Artificial Intelligence_Course Curriculum.pdf';
import appPDF from '../assets/curiculum/Android App Development_Course Curriculum.pdf';
import autocadCPDF from '../assets/curiculum/AutoCad & Revit Civil_Course Curriculum.pdf';
import autocadMPDF from '../assets/curiculum/AutoCAD Mechanical_Course Curriculum.pdf';
import banalyticsPDF from '../assets/curiculum/Business Analytics_Course Curriculum.pdf';
import carPDF from '../assets/curiculum/Car Design_Course Curriculum.pdf';
import cloudPDF from '../assets/curiculum/Cloud Computing_Course Curriculum.pdf';
import constructionPDF from '../assets/curiculum/Construction Planning_Course Curriculum.pdf';
import cyberPDF from '../assets/curiculum/Cyber Security_Course Curriculum.pdf';
import dsPDF from '../assets/curiculum/Data Science_Course Curriculum.pdf';
import digimarPDF from '../assets/curiculum/Digital Marketing_Course Curriculum.pdf';
import embeddedPDF from '../assets/curiculum/Embedded Systems_Course Curriculum.pdf';
import financePDF from '../assets/curiculum/Finance_Course Curriculum.pdf';
import fullstackPDF from '../assets/curiculum/Full Stack Development_Course Curriculum.pdf';
import geneticPDF from '../assets/curiculum/Genetic Engineering_Course Curriculum.pdf';
import graphicPDF from '../assets/curiculum/Graphic Design_Course Curriculum.pdf';
import hrPDF from '../assets/curiculum/Human Resource_Course Curriculum.pdf';
import iotPDF from '../assets/curiculum/Internet Of Things_Course Curriculum.pdf';
import javaPDF from '../assets/curiculum/Java_Course Curriculum.pdf';
import mlPDF from '../assets/curiculum/Machine Learning_Course Curriculum.pdf';
import nanoPDF from '../assets/curiculum/Nano Science & Nano Tech_Course Curriculum.pdf';
import pythonPDF from '../assets/curiculum/Python_Course Curriculum.pdf';
import roboticsPDF from '../assets/curiculum/Robotics_Course Curriculum.pdf';
import salesPDF from '../assets/curiculum/Sales & Marketing_Course Curriculum.pdf';
import sketchupPDF from '../assets/curiculum/Sketchup_Course Curriculum.pdf';
import tableauPDF from '../assets/curiculum/Tableau & Power Bi_Course Curriculum.pdf';
import uiuxPDF from '../assets/curiculum/Ui & Ux_Course Curriculum.pdf';










const domainContent = {
  ai: {
    topic: "Artificial Intelligence",
    description: "Delve into the fascinating world of Artificial Intelligence with Ruddo's specialized course. From machine learning algorithms to natural language processing, explore the cutting-edge technologies and applications driving AI innovation.",
    heroImage: aiHero,
    logos: [numpyLogo, pandasLogo, tensorflowLogo, kerasLogo, scilearnLogo],
    pdf: aiPDF
  },
  datascience: {
    topic: "Data Science",
    description: "Unlock the power of data with Ruddo's Data Science course. From data wrangling to predictive modeling, learn the essential skills and techniques needed to extract actionable insights from complex datasets and drive informed decision-making.",
    heroImage: dsHero,
    pdf: dsPDF,
    logos: [numpyLogo, pandasLogo, scilearnLogo, powerbilogo,mysqllogo,pythonlogo]
  },
  ml: {
    topic: "Machine Learning",
    description: "Unlock the power of Machine Learning with Ruddo's comprehensive course. Dive into the intricacies of predictive modeling, neural networks, and deep learning to understand and apply the algorithms that are revolutionizing data analysis and automation.",
    heroImage: mlHero,
    pdf: mlPDF,
    logos: [pythonlogo, scilearnLogo,pandasLogo,numpyLogo] // Add logos if needed
  },
  cyber: {
    topic: "Cyber Security",
    description: "Secure the digital world with Ruddo's advanced Cyber Security course. Learn about threat detection, risk management, and defense mechanisms to protect systems and data from cyber threats and vulnerabilities, ensuring robust security in an increasingly connected world.",
    heroImage: cyberHero,
    pdf: cyberPDF,
    logos: [burplogo,metaspotlogo, nessuslogo, snortlogo, wiresharklogo] // Add logos if needed
  },
  python: {
    topic: "Python Programming",
    description: "Unlock the power of programming with Ruddo's Python course. Whether you're a seasoned developer or just starting out, dive into the versatile Python language and discover how its simplicity and flexibility can empower you to tackle a wide range of coding challenges.",
    heroImage: pythonHero,
    pdf: pythonPDF,
    logos: [numpyLogo,pythonlogo,matplotliblogo,vscodelogo,pandasLogo,pycharmlogo,jupyterlogo,anacondalogo] // Add logos if needed
  },
  cloud: {
    topic: "Cloud Computing",
    description: "Master the future of IT infrastructure with Ruddo's Cloud Computing course. Discover the principles of cloud services, including IaaS, PaaS, and SaaS, and learn to deploy, manage, and optimize cloud environments for scalable and efficient operations.",
    heroImage: cloudHero,
    logos: [gcplogo, awslogo, azurelogo], // Add logos if needed
    pdf: cloudPDF
  },

  java: {
    topic: "Java Programming",
    description: "Embark on a journey into the world of Java programming with Ruddo's comprehensive course. From building robust applications to crafting scalable web services, learn the language and tools that power some of the world's most innovative software solutions.",
    heroImage: javaHero,
    pdf: javaPDF,
    logos: [netbeanslogo,eclipselogo,junitlogo,ijlogo,mavenlogo,jdklogo] // Add logos if needed
  },
  androidapp: {
    topic: "Android App Development",
    description: "Dive into the exciting world of Android app development with Ruddo's comprehensive course. From designing user interfaces to implementing functionality, learn to build feature-rich apps that delight users and drive engagement.",
    heroImage: androidHero,
    logos: [canarylogo,gradlelogo,retrofitlogo, studiologo, firebaselogo,glidelogo], // Add logos if needed
    pdf: appPDF
  },
  fullstack: {
    topic: "Full Stack Development",
    description: "Master the art of full stack development with Ruddo's comprehensive course. From frontend frameworks to backend technologies, gain the skills and knowledge needed to create dynamic web applications that deliver exceptional user experiences.",
    heroImage: fullstackHero,
    pdf: fullstackPDF,
    logos: [mysqllogo,mongodblogo,githublogo,javascriptlogo,csslogo,htmllogo] // Add logos if needed
  },
  constructionplanning: {
    topic: "Construction Planning and Management",
    description: "Build the future with Ruddo's Construction Planning and Management course. From initial project planning to on-site execution, gain the skills and knowledge needed to oversee construction projects efficiently, effectively, and safely.",
    heroImage: constructionHero,
    logos: [bluebeamlogo,revitlogo,sagelogo, procorelogo, oraclelogo, plangridlogo, aconexlogo, oraclelogo, plangridlogo, aconexlogo, autocadlogo], // Add logos if needed
    pdf: constructionPDF
  },
  sketchup: {
    topic: "SketchUp",
    description: "Bring your designs to life with Ruddo's SketchUp course. Whether you're an aspiring architect, interior designer, or hobbyist, learn to create stunning 3D models and visualizations that captivate audiences and transform your ideas into reality.",
    heroImage: sketchupHero,
    pdf: sketchupPDF,
    logos: [] // Add logos if needed
  },
  autocad: {
    topic: "AutoCAD",
    description: "Enter the realm of precision engineering with Ruddo's AutoCAD Mechanical Engineering course. From intricate designs to seamless simulations, delve into the world of Computer-Aided Design (CAD) and master the tools essential for creating cutting-edge solutions in mechanical engineering.",
    heroImage: autocadHero,
    logos: [autocadlogo,revitlogo], // Add logos if needed
    pdf: autocadCPDF
  },
  autocadm: {
    topic: "AutoCAD",
    description: "Enter the realm of precision engineering with Ruddo's AutoCAD Mechanical Engineering course. From intricate designs to seamless simulations, delve into the world of Computer-Aided Design (CAD) and master the tools essential for creating cutting-edge solutions in mechanical engineering.",
    heroImage: autocadMHero,
    logos: [autocadlogo,revitlogo],  // Add logos if needed
    pdf: autocadMPDF
  },
  embedded: {
    topic: "Embedded Systems",
    description: "Embark on a journey into the heart of technology with Ruddo's embedded systems course. Explore the intricate world of hardware and software integration, and learn to design and deploy cutting-edge embedded solutions that power the devices shaping our modern world.",
    heroImage: embeddedHero,
    pdf:embeddedPDF,
    logos: [simulinklogo, arduinologo, raspberrypilogo,proteuslogo, matlablogo, cpplogo] // Add logos if needed
  },
  iot: {
    topic: "Internet of Things (IoT)",
    description: "Connect to the future with Ruddo's IoT course. Explore the vast network of interconnected devices, learn to harness the power of data-driven insights, and discover how IoT is revolutionizing industries and transforming the way we live and work.",
    heroImage: iotHero,
    pdf: iotPDF,
    logos: [thinkspeaklogo, tflitelogo, awsiotlogo, gcplogo, noderedlogo, mqttlogo, azureiotlogo,ibmlogo, raspberrypilogo, arduinologo] // Add logos if needed
  },
  cardesign: {
    topic: "Car Designing",
    description: "Rev up your creativity and dive into the exciting world of car design with Ruddo's specialized course. From sleek sports cars to futuristic electric vehicles, explore the art and science behind crafting the automobiles of tomorrow.",
    heroImage: cardesignHero, // Add the hero image for Car Designing course
    logos: [rhinologo,aliaslogo, vredlogo, solidworkslogo, blenderlogo, sketchbooklogo, photoshoplogo, gravitysketchlogo, keyshotlogo], // Add logos if needed
    pdf: carPDF
  },

  geniticengineering: {
    topic: "Genetic Engineering",
    description: "Uncover the secrets of life itself with Ruddo's Genetic Engineering course. From modifying DNA to create new organisms to revolutionizing healthcare with gene therapies, dive into the world of genetic manipulation and explore its potential to reshape our future.",
    heroImage: geneticHero, 
    pdf: geneticPDF,
    logos: [] // Add logos if needed
  },
  nanoscience: {
    topic: "Nano Science and Nano Technology",
    description: "Explore the fascinating world of nano-scale materials and technologies with Ruddo's Nano Science and Nano Technology course. Delve into the realm of atoms and molecules, and discover how these tiny structures hold the key to groundbreaking innovations in science and technology.",
    heroImage: nanoHero, 
    pdf: nanoPDF,
    logos: [] // Add logos if needed
  },
  marketing: {
    topic: "Marketing",
    description: "Master the art and science of persuasion with Ruddo's Marketing course. From crafting compelling campaigns to analyzing consumer behavior, learn the strategies and techniques that drive successful marketing initiatives in today's dynamic business landscape.",
    heroImage: marketingHero,
    pdf: salesPDF,
    logos: [tflogo,salesforcelogo, salesloftlogo, crmlogo, pipedrivelogo, hubspotlogo, mailchimplogo] // Add logos if needed
  },
  finance: {
    topic: "Finance",
    description: "Take control of your financial future with Ruddo's comprehensive finance course. Whether you're aiming to navigate the stock market, manage personal finances, or make strategic business decisions, this course equips you with the knowledge and skills needed to thrive in the world of finance.",
    heroImage: financeHero, // Add the hero image for Finance course
    logos: [sqllogo, gglanalyticslogo, powerbilogo, excellogo, tableaulogo, pythonlogo, tflogo, rlogo], // Add logos if needed
    pdf: financePDF
  },
  hr: {
    topic: "Human Resources",
    description: "Transform organizational effectiveness with Ruddo's Human Resources course. Gain expertise in talent management, employee relations, and strategic HR practices to foster a productive and positive workplace environment, driving business success through people management.",
    heroImage: hrHero, 
    pdf: hrPDF,
    logos: [hr1logo,hr2logo,hr3logo,hr4logo,hr5logo,hr6logo,hr7logo,hr8logo] // Add logos if needed
  },
  b_analytics: {
    topic: "Business Analytics",
    description: "Harness the power of data with Ruddo's Business Analytics course. Explore data-driven decision-making, statistical analysis, and data visualization to uncover insights and drive strategic business outcomes in today's data-centric world.",
    heroImage: b_analyticsHero, // Add the hero image for Nano Science and Nano Technology course
    logos: [sqllogo,rlogo,pythonlogo,gglanalyticslogo, powerbilogo, excellogo, tableaulogo], // Add logos if needed
    pdf: banalyticsPDF
  },
  digimar: {
    topic: "Digital Marketing",
    description: "Navigate the dynamic landscape of online marketing with Ruddo's Digital Marketing course. Learn about SEO, social media marketing, content creation, and analytics to craft compelling digital campaigns and grow your online presence effectively.",
    heroImage: digimarHero, // Add the hero image for Nano Science and Nano Technology course
    logos: [ggladlogo, hubspotlogo, gglanalyticslogo, adsenselogo], // Add logos if needed
    pdf: digimarPDF
  },
  tableau: {
    topic: "Tableau and Power BI",
    description: "Unlock the power of data visualization with Ruddo's Tableau and Power BI course. From raw data to actionable insights, master the tools and techniques needed to create compelling visualizations that drive informed decision-making and empower businesses to thrive.",
    heroImage: tableauHero,
    pdf: tableauPDF,
    logos: [rlogo,pythonlogo, cloud_plotlogo, tableaulogo, tableau_plogo, powerbilogo, sqllogo] // Add logos if needed
  },
  uiux: {
    topic: "UI/UX Design",
    description: "Step into the realm of user-centric design with Ruddo's UI/UX course. From intuitive app interfaces to engaging website experiences, uncover the principles and practices behind creating digital products that captivate users and drive success.",
    heroImage: uiuxHero,
    pdf: uiuxPDF,
    logos: [XDlogo, figmalogo, creativelogo, invisionlogo, balsamiqlogo,marvellogo] // Add logos if needed
  },
  graphic: {
    topic: "Graphic Design",
    description: "Unleash your creativity with Ruddo's Graphic Design course. Study design principles, typography, and digital tools to create visually stunning graphics for branding, advertising, and multimedia projects, turning your artistic vision into reality.",
    heroImage: graphicHero,
    pdf: graphicPDF,
    logos: [Adobe_Ailogo, Pslogo, Idlogo] // Add logos if needed
  },
  englishspeaking: {
    topic: "English Speaking",
    description: "Refine your spoken English skills with Ruddo's English Speaking course. From honing conversational fluency to mastering pronunciation, join us on a transformative journey towards confident and articulate communication.",
    heroImage: engHero, // Add the hero image for English Speaking course
    logos: [] // Add logos if needed
  }

};

export default domainContent;
