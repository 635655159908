import React from 'react';
import './Advantages.css'; // create a CSS file for advantages styles if needed
import AdvantageImage1 from '../assets/Asset 11.png';
import AdvantageImage2 from '../assets/Asset 8.png';
import AdvantageImage3 from '../assets/Asset 9.png';
import AdvantageImage4 from '../assets/Asset 13.png';
import AdvantageImage5 from '../assets/Asset_10.png';

const Advantages = () => {
  return (
    <section className='Values'>
      <h1>The Ruddo Advantage</h1>
      <br /><br />
      <div className="advantages">
        <div className="left-advantages">
          <div className="advantage1">
            <img src={AdvantageImage4} alt="Advantage" />
            <p>Industry-focused, non-traditional approach</p>
          </div>
          <div className="advantage1">
            <img src={AdvantageImage1} alt="Advantage" />
            <p>Get expert support</p>
          </div>
          <div className="advantage1">
            <img src={AdvantageImage1} alt="Advantage" />
            <p>Certified industrial experts</p>
          </div>
        </div>
        <div className="right-advantages">
          <div className="advantage1">
            <img src={AdvantageImage5} alt="Advantage" />
            <p>Curated curriculum</p>
          </div>
          <div className="advantage1">
            <img src={AdvantageImage3} alt="Advantage" />
            <p>Resume Enhancement and certification</p>
          </div>
          <div className="advantage1">
            <img src={AdvantageImage2} alt="Advantage" />
            <p>Placement Assistance</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Advantages;
