// LearnPros.js
import React from 'react';
import './LearnPros.css';

import p1 from '../assets/p1.jpg';
import p2 from '../assets/p2.jpg';
import p3 from '../assets/p3.jpg';
import p4 from '../assets/p1.jpg';
import p5 from '../assets/p2.jpg';
import p6 from '../assets/p3.jpg';

const LearnPros = () => {
  const people = [
    { image: p1, name: 'Divya L', designation: 'Data Analyst', experience: '6 years'},
    { image: p2, name: 'Akhila ', designation: 'Human Resources', experience: '7+ years' },
    { image: p3, name: 'Sheethal kate', designation: 'Human Resources', experience: '9 years'},
    { image: p4, name: 'Divya L', designation: 'Data Analyst', experience: '6 years'},
    { image: p5, name: 'Akhila ', designation: 'Human Resources', experience: '7+ years'},
    { image: p6, name: 'Sheethal kate', designation: 'Human Resources', experience: '9 years'}
  ];

  const renderImages = (people) => {
    return people.map((person, index) => (
      <div key={index} className="image-wrapper">
        <img src={person.image} alt={`Image ${index + 1}`} />
        <div className="image-overlay">
          <div className="overlay-text">
            <div className="name">{person.name}</div>
            <div className="designation">{person.designation}</div>
          </div>
          <div className="overlay-experience">
            <div>{person.experience}</div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="learn-pros">
      <div className="content-learn">
        <div className="text-container-learn">
          <div className="title-learn">Learn from the Pros,</div>
          <div className="subtitle-learn">Become a pro.</div>
          <div className="description-learn">
            Whether you're looking to upskill, switch careers, or explore new interests, our diverse course options provide the guidance and support you need to chart your own course to success.
          </div>
        </div>
        <div className="image-container-learn">
          <div className="column">
            {renderImages(people)}
            {renderImages(people)}
          </div>
          <div className="column1">
            {renderImages(people)}
            {renderImages(people)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnPros;
