// AboutHero.js
import React from 'react';
import './AboutHero.css';

const AboutHero = () => {
  return (
    <div className="about-hero">
      <div className="content-about">
        <div className="text-container-about">
          <div className="title-about">We are Ruddo</div>
          <div className="description-about">
            Whether you're looking to upskill, switch careers, or explore new interests, our diverse course options provide the guidance and support you need to chart your own course to success.
          </div>
        </div>

      </div>
    </div>
  );
}

export default AboutHero;
