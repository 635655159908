import React, { useRef } from 'react';
import ListCourses from './ListCourses';
import './Civil.css';
import grid from '../assets/grid.png';
import binary from '../assets/binary.png';
import civil from '../assets/civil.png';
import ai from '../assets/ai.png';
import cad from '../assets/cad.png';
import revit from '../assets/revit.png';

const Civil = () => {
  const listCoursesRef = useRef(null);

  const handleViewCoursesClick = () => {
    if (listCoursesRef.current) {
      listCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="civil">
      <div className="content-civil">
        <div className="text-container-civil">
          <div className="title-civil">Civil</div>
          <div className="description-civil">
          Build a solid foundation with our Civil Engineering courses, focusing on sustainable infrastructure and the art of constructing the world around us.
          </div>
          <button className="round-button-civil" onClick={handleViewCoursesClick}>View Courses</button>
        </div>
        <div className="image-container-civil">
          <div className="stack-imgs-civil">
            <img src={grid} alt="grid" />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={binary} alt="binary" style={{ opacity: 0 }} />
            <img src={civil} alt="civil" />
          </div>
          <div className="scatter-around-civil">
            <img src={ai} alt="Artificial Intelligence" />
            <img src={cad} alt="AutoCad" />
            <img src={revit} alt="Revit Sketchup" />
          </div>
        </div>
      </div>
      <div ref={listCoursesRef}>
        <ListCourses expanded={false} />
      </div>
    </div>
  );
};

export default Civil;
