// CourseHero.js
import React from 'react';
import './CourseHero.css';

import DomainFace from './DomainFace';
import CourseResult from './CourseResult';

const CourseHero = ({ domainContent }) => {
  return (
    <div className='course-hero'>
      <DomainFace domainContent={domainContent} />
      
    </div>
  );
}

export default CourseHero;
