import React from 'react';
import './PopularCourses.css'; // Reuse the same CSS for styling
import demandCoursesContent from './DemandCoursesContent';
import { useNavigate } from 'react-router-dom';

const DemandCourses = ({ domain }) => {
  const navigate = useNavigate();
  let courses = demandCoursesContent[domain];

  const handleCourseClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  // Function to shuffle an array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle the courses array and pick the first 3 elements
  if (courses && courses.length > 3) {
    courses = shuffleArray([...courses]).slice(0, 3);
  }

  if (!courses || courses.length === 0) {
    return <div></div>;
  }

  return (
    <div className='popular-body'>
      <section className="popular-courses">
        <h2>Courses on Demand</h2>
        <div className="popular-courses-list">
          {courses.map((course, index) => (
            <div
              className="course-card"
              key={index}
              onClick={() => handleCourseClick(course.path)}
            >
              <img src={course.image} alt={course.heading} />
              <div className="course-details">
                <h3>{course.heading}</h3>
                <p>{course.description}</p>
                <div className="course-price">
                  
                  <span className="discounted-price">₹{course.discountedPrice}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default DemandCourses;
