// components/Others.js
import React from 'react';
import Reviews from './Reviews';
import PopularCourses from './PopularCourses';
import Advantages from './Advantages';

import './Others.css'; // Import the corresponding CSS file

const Others = () => {
  return (
    <div className="others-container">
      <Reviews />
      <PopularCourses />
      <Advantages />
      
    </div>
  );
}

export default Others;
