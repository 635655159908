// ContactPage.js
import React from 'react';
import Navigation from '../components/Navigation';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';
import ContactHero from '../components/ContactHero';
import './ContactUs.css';


const ContactUs = () => {
  return (
    <div className="contact-page">
      <Navigation />
      <ContactHero />
      <Subscribe />
      <Footer />
      {/* Add your contact form or information here */}
    </div>
  );
}

export default ContactUs;
