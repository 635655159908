import React, { useState } from 'react';
import './Spotlight.css';
import Button from './Button'; // Import the Button component
import offbulb from '../assets/Asset 56.png'; // Adjust the path based on your actual folder structure
import onbulb from '../assets/Asset 59.png';
import MultiStepForm from './MultiStepForm'; // Import the MultiStepForm component

const Spotlight = () => {
  const [showForm, setShowForm] = useState(false);

  const handleEnrollClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  return (
    <div className="spotlight">
      {!showForm ? (
      <div className="content">
        <div className="text-container">
          <div className="Topic2">Step into the spotlight:</div>
          <div className="Titled-Text2">Find Your Unique Internship Journey</div>
          <div className="Subtext1">
            Whether you're looking to upskill, switch careers, or explore new interests, our diverse course options provide the guidance and support you need to chart your own course to success.
          </div>
          <br/>
          <button className='EnButton-white' onClick={handleEnrollClick} ></button>
        </div>
        <div className="bulb-container">
          <img src={isHovered ? onbulb : offbulb} alt="bulb" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} />
          <img src={isHovered2 ? onbulb : offbulb} alt="bulb" onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)} />
          <img src={isHovered3 ? onbulb : offbulb} alt="bulb" onMouseEnter={() => setIsHovered3(true)} onMouseLeave={() => setIsHovered3(false)} />
          <img src={isHovered4 ? onbulb : offbulb} alt="bulb" onMouseEnter={() => setIsHovered4(true)} onMouseLeave={() => setIsHovered4(false)} />
        </div>
      </div>
      ) : (
        <MultiStepForm onClose={handleCloseForm} />
      )}
    </div>
  );
}

export default Spotlight;
