import React, { useRef } from 'react';
import ListCourses from './ListCourses';
import './CSandIT.css'; // Import CSS file
import grid from '../assets/grid.png';
import binary from '../assets/binary.png';
import csit from '../assets/csit.png';
import cloud from '../assets/cloud.png';
import crypto from '../assets/crypto.png';
import db from '../assets/database.png';
import block from '../assets/blockchain.png';
import tag from '../assets/tag.png';
import python from '../assets/python.png';
import { useAppContext } from './Context';

const CSandIT = () => {
  // Create a reference for the ListCourses component
  const listCoursesRef = useRef(null);

  // Function to handle the click event of the "View Courses" button
  const handleViewCoursesClick = () => {
    // Scroll to the ListCourses component
    if (listCoursesRef.current) {
      listCoursesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="cs-and-it">
      <div className="content-cs">
        <div className="text-container-cs">
          <div className="title-cs">Computer Science & IT</div>
          <div className="description-cs">
          Dive into the world of computing with our comprehensive CSE/IT courses, designed to equip you with the skills to innovate and excel in the tech industry.          </div>
          <button className="round-button-cs" onClick={handleViewCoursesClick}>View Courses</button>
        </div>
        <div className="image-container-cs">
          <div className="stack-imgs-cs">
            <img src={grid} alt="grid" />
            <img src={binary} alt="binary" />
            <img src={binary} alt="binary" />
            <img src={csit} alt="csit" />
          </div>
          <div className="scatter-around-cs">
            <img src={python} alt="python" />
            <img src={db} alt="db" />
            <img src={block} alt="blockchain" />
            <img src={cloud} alt="cloud" />
            <img src={tag} alt="tag" />
            <img src={crypto} alt="cryptography" />
          </div>
        </div>
      </div>
      {/* Render the ListCourses component */}
      <div ref={listCoursesRef}>
        <ListCourses expanded={false} selectedCourse={'Computer Science & IT'} />
      </div>
    </div>
  );
};

export default CSandIT;
