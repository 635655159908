// pages/Landing.js
//commited 11/05 praveen
import React from 'react';
import './Landing.css';
import Navigation from '../components/Navigation';
import Hero from '../components/Hero';
import Others from '../components/Others';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';

function Landing() {
  return (
    <div className='home'>
      <Navigation />
      <Hero />
      <Others />
      <Subscribe />
      <Footer />
    </div>
  );
}

export default Landing;