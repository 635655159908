// Curriculum.js

import React, { useState } from 'react';
import Advantages from './Advantages';
import './Curriculum.css';
import MultiStepForm from './MultiStepForm'; // Import the MultiStepForm component
import Modal from './Modal'; // Import the Modal component

const Curriculum = ({ curriculumContent }) => {
  const [selectedModule, setSelectedModule] = useState(null);
  const [showForm, setShowForm] = useState(false); // State to manage the form visibility

  const handleModuleClick = (moduleNumber) => {
    // Toggle the selected module
    setSelectedModule((prevModule) => (prevModule === moduleNumber ? null : moduleNumber));
  };

  const handleApplyClick = () => {
    setShowForm(true); // Show the form when the button is clicked
  };

  const handleCloseForm = () => {
    setShowForm(false); // Hide the form
  };

  // Check if domainContent or domainContent.modules is undefined
  if (!curriculumContent || !curriculumContent.modules) {
    return <div>No Curriculum available</div>;
  }

  return (
    <div className="curriculum-outer">
      <div className={`curriculum-core ${selectedModule ? 'active' : ''}`}>
        <div className="heading">Course Curriculum</div>
        <div className="modules">
          {curriculumContent.modules.map((module) => (
            <div
              key={module.number}
              className={`module ${selectedModule === module.number ? 'active' : ''}`}
              onClick={() => handleModuleClick(module.number)}
            >
              <div className="module-info">
                <div className="module-number">Module {module.number}</div>
                <div className="module-name">{module.name}</div>
                <div className="downward-arrow"></div>
              </div>
              {selectedModule === module.number && (
                <div className="module-details">
                  <ul>
                    {module.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <hr className="journey-divider" />
      <div className="advantages-container">
        <Advantages />
      </div>
      <div className="cta-section">
        <div className="cta-text">Step up to a brighter future</div>
        <div className="price-box">₹ {curriculumContent.price}/-</div>
        <button className="apply-button" onClick={handleApplyClick}>Apply Now</button>
      </div>
      {showForm && (
        <Modal onClose={handleCloseForm}>
          <MultiStepForm onClose={handleCloseForm} />
        </Modal>
      )}
    </div>
  );
};

export default Curriculum;
