import React, { useState, useEffect } from 'react';
import { AppProvider, useAppContext } from './Context';
import './InternOptions.css'; // Import CSS file
import CSandIT from './CSandIT';
import Civil from './Civil';
import Biomed from './Biomed';
import Management from './Management';
import Mechanical from './Mechanical';
import ECEandEEE from './ECEandEEE';
import SkillDevelop from './SkillDevelop';

const 
InternOptions = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { expandedCourse } = useAppContext();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!expandedCourse) {
        setCurrentIndex((prevIndex) => (prevIndex === 6 ? 0 : prevIndex + 1));
      }
    }, 3000); // Change slide duration as needed (e.g., 3000 milliseconds for 3 seconds)

    return () => clearInterval(interval);
  }, [expandedCourse]);


  const components = [<CSandIT />,<ECEandEEE />, <Civil />, <SkillDevelop/>, <Biomed />, <Management />, <Mechanical />];
  // const components = [<CSandIT />,<ECEandEEE />, <Civil />, <SkillDevelop/>, <Biomed />, <Management />, <Mechanical />];

  return (
    <div className="intern-options">
      {components[currentIndex]}
    </div>
  );
};

export default InternOptions;

