import React from 'react';
import './Reviews.css';
import u1 from '../assets/Asset 8.png';

export const Reviews = () => {
  return (
    <div className="body">
      <h2>Don't just take our word for it</h2>
      <p>Trusted by 5,000 + students</p>
      <section className="testimonials">
        
        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>G Sathya Sai</h4>
              <p>AV College of Commerce Science and arts</p>
            </div>
          </div>
          <p>"I understood the classes very well and I got clarity on the subject in the same manner my mentor cleared all the doubts from this classes I improved my stability on the subject thank you ruddo."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>Harshita Solanki</h4>
              <p>Medi-Caps University</p>
            </div>
          </div>
          <p>"The classes were excellent!!! The content was well-organized, and was very easy to understand. The meetings were engaging, and provided real-world examples of Kali and burpsuite that enhanced my learning experience. I really enjoyed the quizzes and the burp labs were very beneficial and all these classes provided me with the basic knowledge of cyber security and enhanced my skills."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>Lata Barfa</h4>
              <p>Medi-Caps University</p>
            </div>
          </div>
          <p>"The classes are well and content are very beneficial for my future. I got all clarity on this subject and my mentor cleared all my doubts regarding this subject. I enjoyed all meetings and this subject enhanced my knowledge of cyber security. It improved my learning skills of this subject."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>Anushka Vishwakarma</h4>
              <p>Med-icaps University</p>
            </div>
          </div>
          <p>"The classes were well and the content were very well organised and got deeply understood the topic. Classes were properly scheduled and also doubts get cleared respective to the topics. Classes were very interactive, enjoyed all meetings. It improved my learning skills of this subject and got keen interest in cyber security."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>Kanak Yadav</h4>
              <p>Medi-Caps University</p>
            </div>
          </div>
          <p>"The classes were really well-organized. The topics were clear & interesting. Every class started on time, and all my doubts were answered by the mentor. The sessions were fun and interactive. This whole experience made me understand cybersecurity better. It also helped me improve my learning skills."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>Isra Zabe</h4>
              <p>Bishop Cotton Women's Christian College</p>
            </div>
          </div>
          <p>"The experience has been really great. I learned a lot about data analysis, making charts, and making smart decisions. Thanks so much to the awesome team at Ruddo for all their help and guidance. I’m very grateful for the chance to learn and grow with such a supportive group."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>Uditanshu Pratap Singh</h4>
              <p>Integral University, Lucknow</p>
            </div>
          </div>
          <p>"Studying cybersecurity at Ruddo for two months was an excellent experience. The program gave me practical skills and in-depth knowledge that I could immediately apply. I am deeply thankful to the dedicated team at Ruddo for their unwavering support and expert guidance. Their mentorship was crucial in my learning process, and I appreciate the opportunity I had to grow in such an encouraging environment."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>Tejas R</h4>
              <p>Christ Academy</p>
            </div>
          </div>
          <p>"Learning AI and Data Science with Ruddo has been a great experience. I want to give a big thank you to my mentor, Neha Ma’am, for her amazing guidance and support. Her expertise and encouragement were key to my progress."</p>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-author">
            <img src={u1} alt="Author" />
            <div>
              <h4>K. Preetham Noel</h4>
              <p>Chaitanya Deemed to be University</p>
            </div>
          </div>
          <p>"My time as a business analysis intern at Ruddo was fantastic. I gained practical experience and a lot of valuable insights into the field. A special thanks to my mentor, Divya, for her fantastic support and guidance. Her expertise and encouragement were incredibly helpful, and I truly appreciate all the support she provided. This opportunity has been a great step forward in my career."</p>
        </div>
        {/* Add more testimonial cards here */}
      </section>
    </div>
  );
}

export default Reviews;
