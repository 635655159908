// CourseResult.js
import React from 'react';
import './CourseResult.css';
import logo1 from '../assets/res1.png'
import logo2 from '../assets/res2.png'
import logo3 from '../assets/res3.png'
import logo4 from '../assets/res4.png'

const CourseResult = () => {
  return (
    <div className='result_hero'>
    <div className='course-result-container'>
      <div className='left-section'>
        <h2>What you'll get as a result</h2>
      </div>
      <div className='right-section'>
        <div className='result-card'>
          <h3>25+ Live Sessions</h3>
          <p>Engage in over 25 live sessions with industry experts, offering real-time learning and interactive experiences.
          </p>
          <img src={logo1} alt="Logo" className='logo1' />
        </div>
        {/* Add three more result cards similar to the above */}
        <div className='result-card'>
          <h3>2 Industry Projects</h3>
          <p>Gain practical experience by working on two industry projects, bridging the gap between theory and real-world application.
          </p>
          <img src={logo2} alt="Logo" className='logo' />
        </div>
        <div className='result-card'>
          <h3>Placement Assitance</h3>
          <p>Benefit from dedicated placement assistance to help you land your dream job with top employers.</p>
          <img src={logo3} alt="Logo" className='logo1' />
        </div>
        <div className='result-card'>
          <h3>Expand Connections</h3>
          <p>Expand your professional network and connect with peers, mentors, and industry leaders to open new career opportunities.</p>
          <img src={logo4} alt="Logo" className='logo' />
        </div>
      </div>
    </div>
    </div> 
  );
};

export default CourseResult;
