import courseImage1 from '../assets/dshero.png';
import courseImage2 from '../assets/mlhero.png';
import courseImage3 from '../assets/aihero.png';

const popularCoursesContent = [
  {
    image: courseImage1,
    heading: 'Data Science',
    description: 'Unlock the power of data with Ruddo\'s Data Science course. From data wrangling to predictive modeling, learn the essential skills and techniques needed to extract actionable insights from complex datasets and drive informed decision-making.',
    originalPrice: 8500,
    discountedPrice: 8500,
    path: '/domain/datascience'
  },
  {
    image: courseImage2,
    heading: 'Machine Learning',
    description: 'Unlock the power of Machine Learning with Ruddo\'s comprehensive course. Dive into the intricacies of predictive modeling, neural networks, and deep learning to understand and apply the algorithms that are revolutionizing data analysis and automation.',
    originalPrice: 9000,
    discountedPrice: 8500,
    path: '/domain/ml'
  },
  {
    image: courseImage3,
    heading: 'Artificial Intelligence',
    description: 'Delve into the fascinating world of Artificial Intelligence with Ruddo\'s specialized course. From machine learning algorithms to natural language processing, explore the cutting-edge technologies and applications driving AI innovation.',
    originalPrice: 10000,
    discountedPrice: 8500,
    path: '/domain/ai'
  }
  // Add more courses as needed
];

export default popularCoursesContent;